<template>
  <v-card>
    <v-card-title>
      <span class="headline"><v-icon>mdi-database-edit</v-icon>入荷データ出力</span>
    </v-card-title>
    <v-divider></v-divider>
    <v-card-text>
      <v-form ref="exportForm" v-model="validExportForm" lazy-validation>
        <v-container>
          <v-row dense>
            <v-col>
              <v-switch
                label="選択入荷データのみ"
                inset
                dense
                v-model="selectData"
                :disabled="selectDataDisable"
                @change="changeSelectData"
              ></v-switch>
            </v-col>
          </v-row>
          <v-row v-if="!selectData" dense>
            <v-col cols="12">
              <dp-date-picker
                type="date"
                label="入荷日"
                v-model="dataExportModel.arrivalPlanDate"
                filled
                dense
                :rules="[rules.validArrivalPlanDate]"
              ></dp-date-picker>
            </v-col>
          </v-row>
          <v-row v-if="selectData" dense>
            <v-col cols="12" sm="12">
              <ag-grid-vue
                :domLayout="domLayout"
                class="ag-theme-alpine"
                :defaultColDef="defaultColDef"
                :columnDefs="columnDefs"
                :alwaysShowHorizontalScroll="true"
                :pagination="true"
                paginationPageSize="10"
                :localeText="localeText"
                :enableCellTextSelection="true"
                @grid-ready="onGridReady"
                :columnTypes="columnTypes"
                :getRowNodeId="(data) => data.identify"
              >
              </ag-grid-vue>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions>
      <v-btn color="secondary" @click="onCancelClick">キャンセル</v-btn>
      <v-spacer></v-spacer>
      <v-btn color="primary" @click="onDataExportClick">出力</v-btn>
    </v-card-actions>
    <v-overlay :value="isLoading">
      <v-progress-circular indeterminate color="primary" size="64"></v-progress-circular>
    </v-overlay>
  </v-card>
</template>

<script>
import { AgGridVue } from "ag-grid-vue";
import { AG_GRID_LOCALE_JA } from "../../models/ag-grid/locales";
import { NumericColumn } from "../../models/ag-grid/columnTypes";
import { Cafereo as CafereoColumnDefs } from "../../consts/columns/DeliveryScheduleColumns";
import { statuses as ApiStatus } from "../../libs/api-client";

export default {
  name: "DeliveryScheduleDataExport",
  props: ["inputModel"],
  components: {
    AgGridVue,
  },
  data() {
    return {
      dataExportModel: null,
      validExportForm: null,
      rules: {
        validArrivalPlanDate: (value) => this.validArrivalPlanDate(value),
      },
      defaultColDef: null,
      columnDefs: null,
      gridApi: null,
      columnApi: null,
      selectedRows: null,
      domLayout: null,
      localeText: AG_GRID_LOCALE_JA,
      scheduleRecords: [],
      selectData: false,
      selectDataDisable: true,
      columnTypes: {
        dpNumericColumn: NumericColumn,
      },
    };
  },
  beforeMount() {
    this.domLayout = "autoHeight";
    this.defaultColDef = {
      filter: "agTextColumnFilter",
      resizable: true,
      sortable: true,
      suppressSizeToFit: true,
      filterParams: {
        newRowsAction: "keep",
      },
    };
    this.columnDefs = this.setColumnDefs();
    this.init(this.inputModel);
  },
  computed: {
    isLoading() {
      return this.$store.getters["ui/isLoading"];
    },
  },
  watch: {
    inputModel(inputModel) {
      this.init(inputModel);
    },
  },
  methods: {
    setColumnDefs() {
      let columnDefs = [
        {
          headerName: "",
          headerCheckboxSelection: true,
          headerCheckboxSelectionFilteredOnly: true,
          checkboxSelection: true,
          filter: false,
          resizable: false,
          sortable: false,
          pinned: "left",
        },
      ];
      columnDefs = columnDefs.concat(CafereoColumnDefs);
      return columnDefs;
    },
    onGridReady(params) {
      this.gridApi = params.api;
      this.columnApi = params.columnApi;
      this.gridApi.setRowData(JSON.parse(JSON.stringify(this.inputModel)));
      var allColumnIds = [];
      this.columnApi.getAllColumns().forEach(function (column) {
        allColumnIds.push(column.colId);
      });
      this.columnApi.autoSizeColumns(allColumnIds);
      this.gridApi.refreshCells();
    },
    // ****************************
    // 画面アクション定義
    // ****************************
    init(prams) {
      this.initDataExportModel();
      if (prams && prams.length > 0) {
        this.selectData = true;
        this.selectDataDisable = false;
      }
    },
    initDataExportModel() {
      this.dataExportModel = {
        arrivalPlanDate: null,
      };
    },
    reset() {
      this.init(this.inputModel);
    },
    changeSelectData() {
      this.$emit("dialogResize", this.selectData);
    },
    onCancelClick() {
      this.$emit("cancel");
    },
    async onDataExportClick() {
      if (this.validate() && this.validSelectData()) {
        try {
          this.loadingOn();
          var condition = {};
          if (this.selectData) {
            let slipNoList = [];
            console.log(this.inputModel);
            for (let model of this.inputModel) {
              slipNoList.push(...model.slipNoList);
            }
            condition.slipNo = slipNoList;
          } else {
            condition.arrivalPlanDate = this.dataExportModel.arrivalPlanDate;
          }
          console.log(condition);
          let exec = true;
          let headers = null;
          while (exec) {
            await new Promise((resolve) => setTimeout(resolve, 1000));
            let response = await this.$store.dispatch("arrivalPlan/outData", {
              contents: condition,
              header: headers,
            });
            switch (response.data?.header.resultCode) {
              case ApiStatus.consts.SUCCESS:
                var result = response.data.contents;
                this.$emit("exported", result.exportFilePath);
                this.reset();
                exec = false;
                break;
              case ApiStatus.consts.BUSINESS_ERROR:
                if (response.data?.header.messages) {
                  var messages = [];
                  Object.keys(response.data?.header.messages).forEach(function (key) {
                    messages.push(response.data?.header.messages[key]?.join("<br>"));
                  });
                  this.$dialog.warning({
                    title: `入荷データ出力`,
                    text: messages.join("<br>"),
                    actions: ["OK"],
                  });
                }
                exec = false;
                break;
              case ApiStatus.consts.PROCESSING:
                headers = { InvokeId: response.data.header.invokeId };
                break;
              default:
                exec = false;
                this.redirectError();
                break;
            }
          }
        } catch (error) {
          console.error("DeliveryScheduleDataExport::onDataExportClick", error);
          this.apiRequestError(error);
        } finally {
          this.loadingOff();
        }
      }
    },
    validate() {
      const isValid = this.$refs.exportForm.validate();
      if (!isValid) {
        this.$dialog.notify.error(`入力エラーがあります`, { timeout: 2300 });
      }
      return isValid;
    },
    validArrivalPlanDate(value) {
      if (this.selectData) return true;
      if (value == null) return "入荷予定日は必須です。";
      return true;
    },
    validSelectData() {
      if (!this.selectData) return true;
      let arrivalPlanDate = null;
      for (let node of this.inputModel) {
        if (arrivalPlanDate == null) {
          arrivalPlanDate = node.arrivalPlanDate;
        }
        if (node.arrivalPlanDate != arrivalPlanDate) {
          this.$dialog.notify.error(`入荷予定日は全て同一日のものを指定してください。`, { timeout: 2300 });
          return false;
        }
        arrivalPlanDate = node.arrivalPlanDate;
      }
      return true;
    },
  },
};
</script>
