<template>
  <arrival-achievement-search></arrival-achievement-search>
</template>

<script>
import ArrivalAchievementSearch from "./ArrivalAchievementSearch.vue";
export default {
  name: "ArrivalAchievementTodaySearch",
  components: {
    ArrivalAchievementSearch,
  },
};
</script>
