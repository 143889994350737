import { DateValueFormatter } from "../../models/ag-grid/valueFormatters";
import FtProductConditionType from "../../consts/FtProductConditionTypes";
import "ag-grid-autocomplete-editor/dist/main.css";

//
const ShippingInstructionColumns = [
  { headerName: "JANCODE", field: "janCode", pinned: "left" },
  { headerName: "タイトル", field: "title" },
  { headerName: "商品名", field: "productName" },
  { headerName: "在庫保管場所", field: "currentStorageName" },
  { headerName: "現在庫数", field: "currentStockQuantity", type: "dpNumericColumn" },
  { headerName: "在庫数(不良)", field: "badQuantity", type: "dpNumericColumn" },
  {
    headerName: "移動予定日(出荷予定日)",
    field: "movingPlanDate",
    type: "dpEditableColumn",
    cellEditorFramework: "CustomDateCellEditor",
    valueFormatter: DateValueFormatter("YYYY/MM/DD"),
    cellStyle: (params) => {
      let ret = { backgroundColor: "#FFFFCC" };
      if (params.data.updateMovingPlanDate) {
        ret.backgroundColor = "#CCFFCC";
      }
      return ret;
    },
  },
  {
    headerName: "配送指定日",
    field: "specifiedDeliveryDate",
    type: "dpEditableColumn",
    cellEditorFramework: "CustomDateCellEditor",
    valueFormatter: DateValueFormatter("YYYY/MM/DD"),
    cellStyle: (params) => {
      let ret = { backgroundColor: "#FFFFCC" };
      if (params.data.updateSpecifiedDeliveryDate) {
        ret.backgroundColor = "#CCFFCC";
      }
      return ret;
    },
  },
  { headerName: "移動先保管場所", field: "movingStorageCode" },
  {
    headerName: "良品不良品区分",
    field: "productConditionType",
    filter: "dpSelectionFilter",
    filterParams: { options: FtProductConditionType.all() },
    valueGetter: (params) => FtProductConditionType.of(params.data.productConditionType),
  },
  {
    headerName: "移動数量",
    field: "movingQuantity",
    type: "dpNumericColumn",
    editable: true,
    cellEditorFramework: "NumericCellEditor",
    cellStyle: (params) => {
      let ret = { backgroundColor: "#FFFFCC" };
      if (params.data.updateMovingQuantity) {
        ret.backgroundColor = "#CCFFCC";
      }
      return ret;
    },
  },
  {
    headerName: "出荷希望",
    field: "isReady",
    type: "dpEditableCheckmarkColumn",
    cellStyle: (params) => {
      let ret = { backgroundColor: "#FFFFCC" };
      if (params.data.updateIsReady) {
        ret.backgroundColor = "#CCFFCC";
      }
      return ret;
    },
  },
];

export default { ShippingInstructionColumns };
export { ShippingInstructionColumns };
