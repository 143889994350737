<template>
  <v-card>
    <v-card-title>
      <span class="headline"><v-icon>mdi-database-refresh</v-icon>{{ title }}</span>
    </v-card-title>
    <v-divider></v-divider>
    <v-card-text>
      <v-form>
        <v-container>
          <v-row dense>
            <v-col cols="12" sm="12">
              <v-select
                v-model="differenceCope"
                item-text="text"
                item-value="value"
                :items="differenceList"
                label="対処内容"
                dense
                filled
                attach
              ></v-select>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions>
      <v-btn color="secondary" @click="$emit('onDialogClose')">キャンセル</v-btn>
      <v-spacer></v-spacer>
      <v-btn color="primary" @click="$emit('onDialogSetting', differenceCope)">選択</v-btn>
    </v-card-actions>
    <v-overlay :value="isLoading">
      <v-progress-circular indeterminate color="primary" size="64"></v-progress-circular>
    </v-overlay>
  </v-card>
</template>

<script>
export default {
  name: "DifferenceSelect",
  props: ["differenceList", "title"],
  data() {
    return {
      differenceCope: null,
    };
  },
  computed: {
    isLoading() {
      return this.$store.getters["ui/isLoading"];
    },
  },
};
</script>
