<style lang="scss">
@import "../../../node_modules/ag-grid-community/dist/styles/ag-grid.css";
@import "../../../node_modules/ag-grid-community/dist/styles/ag-theme-alpine.css";
</style>
<style>
.cell-span {
  background-color: #ffffff;
  border-bottom-color: #dde2eb !important;
  border-bottom-style: solid !important;
  border-bottom-width: 1px !important;
}
</style>

<template>
  <v-container fluid style="height: 100%">
    <v-row>
      <v-app-bar dense>
        <v-app-bar-title><v-icon>mdi-database-lock</v-icon>確保付け</v-app-bar-title>
        <v-spacer></v-spacer>
        <v-form ref="searchForm" v-model="validSeearchForm" lazy-validation>
          <search-conditions @search="onBtnSearch" max-height="1000">
            <v-row dense>
              <v-checkbox
                v-if="isCafereoUser"
                label="検索上限無し"
                v-model="searchModel.fetchLimitOverFlg"
                dense
              ></v-checkbox>
            </v-row>
            <v-row dense>
              <v-col>
                <dp-date-picker
                  v-model="searchModel.shipmentPlanDateFrom"
                  class="mx-2"
                  label="出荷予定日From"
                  dense
                  type="date"
                  :rules="[rules.shipmentedFrom]"
                ></dp-date-picker>
              </v-col>
              <v-col>
                <dp-date-picker
                  v-model="searchModel.shipmentPlanDateTo"
                  class="mx-2"
                  label="出荷予定日To"
                  dense
                  type="date"
                  :rules="[rules.shipmentedTo]"
                ></dp-date-picker>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col>
                <v-text-field
                  v-model="searchModel.productName"
                  label="商品名"
                  dense
                  class="mx-2"
                  :rules="[rules.maxLength(60)]"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col>
                <v-text-field
                  v-model="searchModel.title"
                  label="タイトル"
                  dense
                  class="mx-2"
                  :rules="[rules.maxLength(150)]"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col>
                <v-textarea
                  v-model="searchModel.jancodeList"
                  class="mx-2"
                  :rules="[rules.maxLength(1400), rules.isJancodeSearchList]"
                  label="JANコード"
                  rows="1"
                  dense
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col>
                <v-text-field
                  v-model="searchModel.corporationName"
                  label="法人名"
                  dense
                  class="mx-2"
                  :rules="[rules.maxLength(150)]"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col>
                <v-text-field
                  v-model="searchModel.supplierName"
                  label="メーカー名"
                  dense
                  class="mx-2"
                  :rules="[rules.maxLength(150)]"
                ></v-text-field>
              </v-col>
            </v-row>
          </search-conditions>
        </v-form>
        <tooltip-icon-button icon="mdi-refresh" @click="onBtnSearch">リフレッシュ</tooltip-icon-button>
        <v-divider vertical></v-divider>
        <tooltip-icon-button
          v-if="allowedAction(['C040502'])"
          icon="mdi-database-check"
          @click="onBtnFix"
          :disabled="selectionRows.length === 0"
          >確定</tooltip-icon-button
        >
        <v-divider vertical></v-divider>
        <tooltip-icon-button
          v-if="allowedAction(['C040503'])"
          icon="mdi-database-refresh"
          @click="onBtnSelectStorage"
          :disabled="selectableStorages.length === 0"
          >確保元選択</tooltip-icon-button
        >
        <tooltip-icon-button icon="mdi-filter-off" @click="clearFilters()">フィルター解除</tooltip-icon-button>
        <v-dialog v-model="selectStorageDialog" max-width="500px" persistent scrollable>
          <v-card>
            <v-card-title>
              <span class="headline"><v-icon>mdi-database-refresh</v-icon>確保元選択</span>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text>
              <v-form>
                <v-container>
                  <v-row dense>
                    <v-col cols="12" sm="12">
                      <v-select
                        :items="selectableStorages"
                        label="在庫確保元"
                        dense
                        filled
                        v-model="selectStorageNumber"
                      ></v-select>
                    </v-col>
                  </v-row>
                </v-container>
              </v-form>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
              <v-btn color="secondary" @click="onSelectStorageCancel">キャンセル</v-btn>
              <v-spacer></v-spacer>
              <v-btn color="primary" @click="onSelectStorageSubmit">選択</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-app-bar>
    </v-row>
    <v-row style="height: 100%">
      <v-col :style="gridStyle">
        <ag-grid-vue
          id="InsureFixList"
          class="ag-theme-alpine"
          style="height: 100%"
          :gridOptions="gridOptions"
          v-model="rowData"
          @cellValueChanged="onCellValueChanged"
        ></ag-grid-vue>
      </v-col>
    </v-row>
    <error-grid-dialog ref="bulkErrorGrid" width="80%" height="80%" :columns="errorColmuns"></error-grid-dialog>
  </v-container>
</template>

<script>
import moment from "moment";
import Validation from "../../libs/validation";
import { AgGridVue } from "ag-grid-vue";
import { AG_GRID_LOCALE_JA } from "../../models/ag-grid/locales";
import {
  NumericColumn,
  PercentColumn,
  DateColumn,
  FullDateColumn,
  CheckmarkColumn,
  EditableColumn,
  IncludeFilter,
} from "../../models/ag-grid/columnTypes";
import { BooleanFilter, SelectionFilter } from "../../components/ag-grid/filters";
import { NumericCellEditor } from "../../components/ag-grid/cellEditors";
import OrderStatus from "../../consts/orderStatus/BaseOrderStatuses";
import SearchConditions from "./../../components/common/SearchConditions.vue";
import TooltipIconButton from "../../components/common/TooltipIconButton.vue";
import { statuses as ApiStatus } from "../../libs/api-client";
import ErrorGridDialog from "../../components/common/ErrorGridDialog.vue";
import RequestUtils from "../../utils/RequestUtils";

export default {
  name: "InsureFix",
  data() {
    const self = this;
    return {
      firstTime: 0,
      countTime: 0,
      gridStyle: { height: "95%" },
      selectionRows: [],
      gridOptions: {
        columnTypes: {
          dpNumericColumn: NumericColumn,
          dpPercentColumn: PercentColumn,
          dpDateColumn: DateColumn,
          dpFullDateColumn: FullDateColumn,
          dpCheckmarkColumn: CheckmarkColumn,
          dpEditableColumn: EditableColumn,
          dpMergeColumn: {
            cellStyle: (data) => {
              if (data.data.colorFlg) {
                return { backgroundColor: "#FFEEFF" };
              } else {
                return { backgroundColor: "#FFFFFF" };
              }
            },
          },
        },
        frameworkComponents: {
          dpBooleanFilter: BooleanFilter,
          dpSelectionFilter: SelectionFilter,
          dpNumericCellEditor: NumericCellEditor,
        },
        defaultColDef: {
          filter: "agTextColumnFilter",
          resizable: true,
          sortable: true,
          suppressSizeToFit: true,
          filterParams: {
            newRowsAction: "keep",
          },
        },
        columnDefs: [
          {
            headerName: "No.",
            field: "number",
            type: "dpMergeColumn",
            filter: false,
          },
          { headerName: "JANCODE", field: "janCode", type: "dpMergeColumn", sortable: true },
          {
            headerName: "タイトル",
            field: "title",
            type: "dpMergeColumn",
            filterParams: IncludeFilter,
          },
          {
            headerName: "商品名",
            field: "productName",
            type: "dpMergeColumn",
            filterParams: IncludeFilter,
          },
          {
            headerName: "Box入数",
            field: "inBoxQuantity",
            type: "dpNumericColumn,dpMergeColumn",
          },
          {
            headerName: "CT中Box入数",
            field: "inCtBoxQuantity",
            type: "dpNumericColumn,dpMergeColumn",
          },
          {
            headerName: "CT中pcs入数",
            field: "inCtPcsQuantity",
            type: "dpNumericColumn,dpMergeColumn",
          },
          {
            headerName: "CTsizeW",
            field: "ctSizeWidth",
            type: "dpNumericColumn,dpMergeColumn",
          },
          {
            headerName: "CTsizeD",
            field: "ctSizeDepth",
            type: "dpNumericColumn,dpMergeColumn",
          },
          {
            headerName: "CTsizeH",
            field: "ctSizeHeight",
            type: "dpNumericColumn,dpMergeColumn",
          },
          {
            headerName: "在庫",
            children: [
              { headerName: "保管場所", field: "storageName", type: "dpMergeColumn" },
              {
                headerName: "在庫数",
                field: "stockQuantity",
                type: "dpNumericColumn,dpMergeColumn",
              },
              {
                headerName: "確保可能数",
                field: "afterInsureQuantity",
                type: "dpNumericColumn,dpMergeColumn",
              },
            ],
          },
          {
            headerName: "確保",
            children: [
              {
                headerName: "確保",
                checkboxSelection: (params) => params.data.orderRow,
                headerCheckboxSelection: true,
                headerCheckboxSelectionFilteredOnly: true,
              },
              {
                headerName: "確保数",
                field: "insureQuantity",
                type: "dpEditableColumn,dpNumericColumn",
                cellEditor: "dpNumericCellEditor",
                valueSetter: (params) => {
                  let isNumber = Validation.isNumber(params.newValue);
                  if (isNumber !== true) {
                    this.$dialog.notify.error("確保数は" + isNumber, { timeout: 2300 });
                    return false;
                  }
                  if (params.newValue > params.data.orderQuantity) {
                    this.$dialog.notify.error("受注数を超えて確保することはできません", { timeout: 2300 });
                    return false;
                  }
                  if (Number(params.newValue) > params.data.afterInsureQuantity + Number(params.oldValue)) {
                    this.$dialog.notify.error("在庫数を超えて確保することはできません", { timeout: 2300 });
                    return false;
                  }
                  params.data.insureQuantity = params.newValue;
                  return true;
                },
                editable: (params) => params.data.orderRow,
                cellStyle: (params) => (params.data.orderRow ? { backgroundColor: "#FFEDB3" } : undefined),
              },
            ],
          },
          {
            headerName: "受注",
            children: [
              { headerName: "社店CD", field: "customerCode" },
              { headerName: "法人名", field: "corporationName" },
              { headerName: "取引先名", field: "customerName", filterParams: IncludeFilter },
              {
                headerName: "受注数",
                field: "orderQuantity",
                type: "dpNumericColumn",
              },
              { headerName: "受注ID", field: "orderId" },
              { headerName: "受注日", field: "orderDate", type: "dpDateColumn" },
              { headerName: "出荷予定日", field: "shipmentPlanDate", type: "dpDateColumn" },
              { headerName: "発売日", field: "releaseDate" },
              { headerName: "伝票備考", field: "slipRemarks" },
              { headerName: "社内備考", field: "cafereoRemarks" },
              { headerName: "アシスタント名", field: "salesAssistant" },
              { headerName: "フォーキャスト", field: "forecastFlg", type: "dpCheckmarkColumn" },
            ],
          },

          {
            headerName: "登録者",
            field: "insureUser",
          },
        ],
        suppressCsvExport: false,
        suppressExcelExport: true,
        alwaysShowHorizontalScroll: true,
        rowSelection: "multiple",
        rowMultiSelectWithClick: true,
        singleClickEdit: true,
        suppressCellSelection: true,
        pagination: true,
        paginationPageSize: null,
        enableCellTextSelection: true,
        suppressColumnVirtualisation: true,
        suppressRowTransform: true,
        localeText: AG_GRID_LOCALE_JA,
        getRowNodeId: (data) => data.identify,
        isRowSelectable: (rowNode) => rowNode.data.orderRow,
        onSelectionChanged(event) {
          if (self.firstTime <= self.countTime) {
            self.selectionRows = event.api.getSelectedRows();
          }
          self.countTime++;
        },
        onRowDataChanged: (event) => {
          self.selectionRows = event.api.getSelectedRows();
          self.firstTime = self.selectionRows.length * 2;
        },
        defaultCsvExportParams: {
          allColumns: true,
          onlySelectedAllPages: false,
          fileName: "エラーメッセージ.csv",
        },
      },
      errorColmuns: [
        { headerName: "受注ID", field: "orderId" },
        {
          headerName: "エラー内容",
          field: "errorMessage",
          wrapText: true,
          autoHeight: true,
          cellRenderer: function (param) {
            return param.data.errorMessage.join("<br>");
          },
        },
      ],
      rowData: [],
      searchModel: {
        shipmentPlanDateFrom: "",
        shipmentPlanDateTo: "",
      },
      selectStorageDialog: false,
      selectStorageNumber: null,
      stockedStorageMap: [],
      validSeearchForm: null,
      rules: {
        maxLength: Validation.maxLength,
        shipmentedFrom: (value) => this.shipmentedFromRules(value),
        shipmentedTo: (value) => this.shipmentedToRules(value),
        isJancodeSearchList: Validation.isJancodeSearchList,
      },
    };
  },
  components: {
    AgGridVue,
    SearchConditions,
    TooltipIconButton,
    ErrorGridDialog,
  },
  beforeMount() {
    this.gridOptions.paginationPageSize = this.globalPageSize;
  },
  mounted() {
    this.handleResize();
    this.gridStyle.height = this.gridHeightSize + "px";
    window.addEventListener("resize", this.handleResize);
  },
  watch: {
    globalPageSize(size) {
      this.gridOptions.api.paginationSetPageSize(size);
    },
    gridHeightSize(value) {
      this.gridStyle.height = value + "px";
    },
  },
  computed: {
    selectedRow() {
      return this.selectionRows.length === 1 ? this.selectionRows[0] : null;
    },
    selectableStorages() {
      const storages = this.stockedStorageMap[this.selectedRow?.janCode];
      return storages && storages.length ? storages : [];
    },
  },
  methods: {
    clearFilters() {
      this.gridOptions.api.setFilterModel(null);
      this.gridOptions.columnApi.applyColumnState({
        defaultState: { sort: null },
      });
    },
    async onSearchClick() {
      try {
        this.loadingOn();
        this.firstTime = 0;
        this.countTime = 0;
        this.searchModel.jancode = this.searchModel.jancodeList
          ? this.searchModel.jancodeList
              .replaceAll(/\n+/g, " ")
              .split(" ")
              .filter((n) => n)
          : null;
        const response = await this.$store.dispatch("insure/search", this.searchModel);
        const insureRecords = [];
        const stockedStorageMap = {};
        if (ApiStatus.isSystemError(response.data?.header)) {
          return this.redirectError();
        }
        var result = response.data.contents;
        if (result.over) {
          this.$dialog.warning({
            title: "確保付け",
            text: `検索上限数を超えました。結果は${result.limit}件まで表示されます。`,
            actions: ["OK"],
          });
        }
        if (Object.keys(result.stocks).length === 0) {
          this.$dialog.warning({
            title: "確保付け",
            text: `検索結果は0件です。`,
            actions: ["OK"],
          });
        }
        let rowNumber = 1;
        let colorFlg = false;
        result.stocks.forEach((record) => {
          if (rowNumber % 2 === 0) {
            colorFlg = true;
          } else {
            colorFlg = false;
          }
          if (record.orders.length === 0) {
            insureRecords.push({
              identify: `${record.janCode}-${record.storageCode}`,
              number: rowNumber,
              // rowspan: 1,
              colorFlg: colorFlg,
              janCode: record.janCode,
              title: record.title,
              productName: record.productName,
              storageCode: record.storageCode,
              storageName: record.storageName,
              stockQuantity: record.stockQuantity,
              afterInsureQuantity: record.stockQuantity,
              inBoxQuantity: record.inBoxQuantity,
              inCtBoxQuantity: record.inCtBoxQuantity,
              inCtPcsQuantity: record.inCtPcsQuantity,
              ctSizeWidth: record.ctSizeWidth,
              ctSizeDepth: record.ctSizeDepth,
              ctSizeHeight: record.ctSizeHeight,
              orderId: null,
              orderDate: null,
              shipmentPlanDate: null,
              releaseDate: null,
              cafereoRemarks: null,
              slipRemarks: null,
              salesAssistant: null,
              forecastFlg: null,
              customerCode: null,
              customerName: null,
              orderQuantity: null,
              insureQuantity: null,
              insureSettled: false,
              insureUser: null,
              orderRow: false,
              errorMessage: null,
            });
            rowNumber++;
          } else {
            let tmpInsureQuantity = record.stockQuantity;
            let afterInsureQuantity = record.stockQuantity;
            let enough = true;
            for (let order of record.orders) {
              let tmp = afterInsureQuantity;
              tmp -= order.orderQuantity;
              if (tmp >= 0 && enough) {
                afterInsureQuantity = tmp;
              } else {
                enough = false;
              }
            }
            record.orders.forEach((order) => {
              tmpInsureQuantity -= order.orderQuantity;
              insureRecords.push({
                number: rowNumber,
                identify: `${record.janCode}-${record.storageCode}-${order.orderId}`,
                // rowspan: orderIndex === 0 ? record.orders.length : 1,
                colorFlg: colorFlg,
                janCode: record.janCode ? record.janCode : order.janCode,
                title: record.title,
                productName: record.productName,
                storageCode: record.storageCode,
                storageName: record.storageName,
                stockQuantity: record.stockQuantity,
                inBoxQuantity: record.inBoxQuantity,
                inCtBoxQuantity: record.inCtBoxQuantity,
                inCtPcsQuantity: record.inCtPcsQuantity,
                ctSizeWidth: record.ctSizeWidth,
                ctSizeDepth: record.ctSizeDepth,
                ctSizeHeight: record.ctSizeHeight,
                afterInsureQuantity: afterInsureQuantity,
                orderId: order.orderId,
                orderDate: order.orderDate,
                shipmentPlanDate: order.shipmentPlanDate,
                releaseDate: order.releaseDate,
                cafereoRemarks: order.cafereoRemarks,
                slipRemarks: order.slipRemarks,
                salesAssistant: order.salesAssistant,
                forecastFlg: order.forecastFlg,
                corporationName: order.corporationName,
                customerCode: order.customerCode,
                customerName: order.customerName,
                orderQuantity: order.orderQuantity,
                insureQuantity: tmpInsureQuantity >= 0 ? order.orderQuantity : 0,
                insureSettled: order.orderStatus === OrderStatus.SECURED,
                insureUser: order.insureUser,
                orderRow: record.stockFlg ? false : true,
                lastUpdateDatetime: order.lastUpdateDatetime,
                errorMessage: null,
              });
            });
            rowNumber++;
          }
          if (!(record.janCode in stockedStorageMap)) {
            stockedStorageMap[record.janCode] = [];
          }
          stockedStorageMap[record.janCode].push({
            text: `${record.storageCode} : ${record.storageName}`,
            value: record.storageCode,
          });
        });
        this.stockedStorageMap = stockedStorageMap;
        this.gridOptions.api.setRowData(insureRecords);
        const allColumnIds = this.gridOptions.columnApi.getAllColumns().map((column) => column.colId);
        this.gridOptions.columnApi.autoSizeColumns(allColumnIds);
        // this.gridOptions.api.forEachNode(function (node) {
        //   node.setSelected(node.data.insureQuantity === node.data.orderQuantity);
        // });
        this.gridOptions.api.redrawRows();
      } catch (error) {
        console.error("InsureFix::onSearchClick", error);
        this.apiRequestError(error);
      } finally {
        this.loadingOff();
      }
    },
    onCellValueChanged(event) {
      if (event.oldValue === event.newValue) {
        return;
      }
      if (event.column.colId !== "insureQuantity") {
        return;
      }
      const afterInsureQuantity = event.data.afterInsureQuantity + Number(event.oldValue) - Number(event.newValue);
      this.gridOptions.api.forEachNode((rowNode) => {
        if (rowNode.data.number === event.data.number) {
          rowNode.setDataValue("afterInsureQuantity", afterInsureQuantity);
        }
      });
    },
    async onBtnFix() {
      let messageText = `選択されたデータの確保付けを確定します。<small>(${this.selectionRows.length}件)</small>`;
      const ok = await this.$dialog.confirm({ title: "確保付け確定", text: messageText });
      if (ok) {
        try {
          this.loadingOn();
          this.gridOptions.api.stopEditing(false);
          let orders = [];
          this.selectionRows.forEach((row) => {
            if (row.insureQuantity > 0) {
              orders.push({
                orderId: row.orderId,
                storageCode: row.storageCode,
                insureQuantity: parseInt(row.insureQuantity, 10),
                lastUpdateDatetime: row.lastUpdateDatetime,
              });
            }
          });
          if (orders.length === 0) {
            this.$dialog.notify.error(`確保数を1以上指定してください。`, {
              timeout: 2300,
            });
            return;
          }
          // 最大件数チェック
          const validDataSizeRet = await RequestUtils.validDataSize("C040502", orders.length, (limitSize) => {
            this.$dialog.notify.error(`最大処理件数（${limitSize}件）オーバーのため処理出来ませんでした。`, {
              timeout: 2300,
            });
            this.loadingOff();
          });
          if (!validDataSizeRet) {
            return;
          }
          let result = await this.$store.dispatch("insure/fix", {
            rows: orders,
            splitNum: 100,
          });

          let error = result.data?.header;
          let errorRows = [];

          switch (error.resultCode) {
            case ApiStatus.consts.SUCCESS:
              await this.onSearchClick();
              this.$dialog.notify.info(`確保数を確定しました (${this.selectionRows.length}件)`, { timeout: 2300 });
              break;
            case ApiStatus.consts.BUSINESS_ERROR:
              await this.onSearchClick();
              // エラーメッセージ格納
              if (error.messages) {
                Object.keys(error.messages).forEach((key) => {
                  errorRows.push({
                    orderId: key,
                    errorMessage: error.messages[key],
                  });
                });
              }
              this.$refs.bulkErrorGrid.open({ title: "確保付け", records: errorRows });
              this.$dialog.notify.error(`確保数に確定エラーがあります。確認してください。`, { timeout: 2300 });
              break;
            default:
              this.redirectError();
              break;
          }
          if (result.data?.contents?.exportFilePath) window.open(result.data?.contents?.exportFilePath, "_blank");
        } catch (error) {
          console.error("InsureFix::onBtnFix", error);
          this.apiRequestError(error);
        } finally {
          this.loadingOff();
        }
      }
    },
    onBtnSelectStorage() {
      this.selectStorageDialog = true;
    },
    onSelectStorageCancel() {
      this.selectStorageNumber = null;
      this.selectStorageDialog = false;
    },
    onSelectStorageSubmit() {
      const srcNumber = this.selectedRow.storageCode;
      const dstNumber = this.selectStorageNumber;
      if (srcNumber == dstNumber) {
        this.$dialog.notify.error(`確保元が同一です (倉庫CD：${dstNumber})`, { timeout: 2300 });
        return;
      }
      const srcIdentify = this.selectedRow.identify;
      const rowNode = this.gridOptions.api.getRowNode(srcIdentify);
      let beforeRow = { ...rowNode.data };

      // 移動先の確保情報を探す
      let afterRows = [];
      let beforeRows = [];
      this.gridOptions.api.forEachNode((row, index) => {
        if (row.data.janCode == rowNode.data.janCode && row.data.storageCode == dstNumber) {
          afterRows.push({ data: { ...row.data }, index: index });
        }
        // 移動元の自分以外を取得
        if (
          row.data.janCode == rowNode.data.janCode &&
          row.data.storageCode == rowNode.data.storageCode &&
          row.data.orderId != rowNode.data.orderId
        ) {
          beforeRows.push({ data: { ...row.data }, index: index });
        }
      });

      let updateRow = { ...afterRows[afterRows.length - 1].data };
      let updateIndex = afterRows[afterRows.length - 1].index;
      // 移動先の確保情報のレコードを追加
      // 移動先の確保情報が1レコードの場合、その行に情報設定
      if (updateRow.orderId == null) {
        updateRow.orderId = rowNode.data.orderId;
        updateRow.orderDate = rowNode.data.orderDate;
        updateRow.shipmentPlanDate = rowNode.data.shipmentPlanDate;
        updateRow.releaseDate = rowNode.data.releaseDate;
        updateRow.cafereoRemarks = rowNode.data.cafereoRemarks;
        updateRow.slipRemarks = rowNode.data.slipRemarks;
        updateRow.salesAssistant = rowNode.data.salesAssistant;
        updateRow.forecastFlg = rowNode.data.forecastFlg;
        updateRow.corporationName = rowNode.data.corporationName;
        updateRow.customerCode = rowNode.data.customerCode;
        updateRow.customerName = rowNode.data.customerName;
        updateRow.orderQuantity = rowNode.data.orderQuantity;
        updateRow.insureQuantity = 0;
        updateRow.insureSettled = rowNode.data.insureSettled;
        updateRow.insureUser = rowNode.data.insureUser;
        updateRow.orderRow = true;
        updateRow.lastUpdateDatetime = rowNode.data.lastUpdateDatetime;
        this.gridOptions.api.applyTransaction({ update: [updateRow] });
      } else {
        // rowspanを変更
        // afterRows[0].data.rowspan = afterRows.length == 1 ? afterRows.length + 1 : afterRows.length;
        this.gridOptions.api.applyTransaction({ update: [afterRows[0].data] });

        updateRow.identify = `${rowNode.data.janCode}-${dstNumber}-${rowNode.data.orderId}`;
        updateRow.orderId = rowNode.data.orderId;
        updateRow.orderDate = rowNode.data.orderDate;
        updateRow.shipmentPlanDate = rowNode.data.shipmentPlanDate;
        updateRow.releaseDate = rowNode.data.releaseDate;
        updateRow.cafereoRemarks = rowNode.data.cafereoRemarks;
        updateRow.slipRemarks = rowNode.data.slipRemarks;
        updateRow.salesAssistant = rowNode.data.salesAssistant;
        updateRow.forecastFlg = rowNode.data.forecastFlg;
        updateRow.corporationName = rowNode.data.corporationName;
        updateRow.customerCode = rowNode.data.customerCode;
        updateRow.customerName = rowNode.data.customerName;
        updateRow.orderQuantity = rowNode.data.orderQuantity;
        updateRow.insureQuantity = 0;
        updateRow.insureSettled = rowNode.data.insureSettled;
        updateRow.insureUser = rowNode.data.insureUser;
        updateRow.orderRow = true;
        updateRow.lastUpdateDatetime = rowNode.data.lastUpdateDatetime;
        this.gridOptions.api.applyTransaction({ add: [updateRow], addIndex: updateIndex + 1 });
      }

      // 移動元の確保情報を削除
      if (beforeRows.length == 0) {
        beforeRow.orderId = null;
        beforeRow.orderDate = null;
        beforeRow.shipmentPlanDate = null;
        beforeRow.releaseDate = null;
        beforeRow.cafereoRemarks = null;
        beforeRow.slipRemarks = null;
        beforeRow.salesAssistant = null;
        beforeRow.forecastFlg = null;
        beforeRow.corporationName = null;
        beforeRow.customerCode = null;
        beforeRow.customerName = null;
        beforeRow.orderQuantity = null;
        beforeRow.insureQuantity = null;
        beforeRow.insureSettled = null;
        beforeRow.insureUser = null;
        beforeRow.orderRow = false;
        beforeRow.lastUpdateDatetime = null;
        this.gridOptions.api.applyTransaction({ update: [beforeRow] });
      } else {
        // beforeRows[0].data.rowspan = beforeRows.length;
        this.gridOptions.api.applyTransaction({ update: [beforeRows[0].data] });
        this.gridOptions.api.applyTransaction({ remove: [beforeRow] });
      }

      // redraw
      this.gridOptions.api.redrawRows();

      this.selectStorageDialog = false;
      this.selectStorageNumber = null;
      this.$dialog.notify.info(`在庫の確保元を変更しました (No.${srcNumber} → No.${dstNumber})`, { timeout: 2300 });
    },
    onBtnExport() {
      var allColumnIds = [];
      this.gridOptions.columnApi.getAllColumns().forEach(function (column) {
        allColumnIds.push(column.colId);
      });
      this.gridOptions.api.exportDataAsCsv(allColumnIds);
    },
    onBtnSearch() {
      const isValid = this.$refs.searchForm.validate();
      if (!isValid) {
        this.$dialog.notify.error(`入力エラーがあります`, { timeout: 2300 });
        return;
      }
      this.$refs.searchForm.resetValidation();
      this.onSearchClick();
    },
    shipmentedFromRules(value) {
      if (value == null || this.searchModel.shipmentPlanDateTo == null) return true;
      if (moment(value).isAfter(this.searchModel.shipmentPlanDateTo))
        return "出荷予定日Toより前の日付を指定してください";
      return true;
    },
    shipmentedToRules(value) {
      if (value == null || this.searchModel.shipmentPlanDateFrom == null) return true;
      if (moment(value).isBefore(this.searchModel.shipmentPlanDateFrom))
        return "出荷予定日Fromより後の日付を指定してください";
      return true;
    },
  },
};
</script>
