<template>
  <v-card>
    <v-card-title>
      <span class="headline"><v-icon>mdi-database-edit</v-icon>出荷指示編集</span>
    </v-card-title>
    <v-divider></v-divider>
    <v-card-text>
      <v-form ref="shippingInstructionEntryForm" lazy-validation>
        <v-container>
          <v-row v-for="(source, index) in settingSources" :key="index" dense>
            <v-col cols="3">
              <v-select
                :items="constant.shippingInstructionEntryColumnsValues"
                label="設定項目"
                v-model="source.column"
                :rules="[rules.required, rules.validSelectColumn]"
                @change="onSettingColSelected(index)"
                dense
                filled
                attach
              ></v-select>
            </v-col>
            <v-col cols="5">
              <dp-date-picker
                v-if="source.column === constant.shippingInstructionEntryColumns.SHIP_DATE"
                label="設定値"
                v-model="source.value"
                type="date"
                filled
                dense
              ></dp-date-picker>
              <dp-date-picker
                v-if="source.column === constant.shippingInstructionEntryColumns.SPECIFIED_DELIVERY_DATE"
                label="設定値"
                v-model="source.value"
                type="date"
                filled
                dense
              ></dp-date-picker>
              <v-text-field
                v-if="source.column === constant.shippingInstructionEntryColumns.CARTON_NUMBER"
                label="設定値"
                v-model="source.value"
                :rules="[rules.isFileName]"
                filled
                dense
              ></v-text-field>
              <v-switch
                v-if="source.column === constant.shippingInstructionEntryColumns.USE_SAGAWA"
                v-model="source.value"
                inset
                dense
              ></v-switch>
              <v-switch
                v-if="source.column === constant.shippingInstructionEntryColumns.USE_CHARTER"
                v-model="source.value"
                inset
                dense
              ></v-switch>
              <v-switch
                v-if="source.column === constant.shippingInstructionEntryColumns.PRIORITY_AM"
                v-model="source.value"
                inset
                dense
              ></v-switch>
              <v-switch
                v-if="source.column === constant.shippingInstructionEntryColumns.USE_ORICON"
                v-model="source.value"
                inset
                dense
              ></v-switch>
              <v-switch
                v-if="source.column === constant.shippingInstructionEntryColumns.GACHA_PRODUCTS"
                v-model="source.value"
                inset
                dense
              ></v-switch>
              <v-switch
                v-if="source.column === constant.shippingInstructionEntryColumns.BUNDLED_DESIGNATION"
                v-model="source.value"
                inset
                dense
              ></v-switch>
              <v-switch
                v-if="source.column === constant.shippingInstructionEntryColumns.PRE_SHIPMENT"
                v-model="source.value"
                inset
                dense
              ></v-switch>
              <v-switch
                v-if="source.column === constant.shippingInstructionEntryColumns.CASH_ON_DELIVERY"
                v-model="source.value"
                inset
                dense
              ></v-switch>
              <v-switch
                v-if="source.column === constant.shippingInstructionEntryColumns.USE_SHIPPING_LABEL"
                v-model="source.value"
                inset
                dense
              ></v-switch>
              <!--<v-text-field
                v-if="source.column === constant.shippingInstructionEntryColumns.REMARKS"
                v-model="source.value"
                filled
                dense
              ></v-text-field>-->
              <v-text-field
                v-if="source.column === constant.shippingInstructionEntryColumns.SLIP_REMARKS"
                v-model="source.value"
                filled
                dense
              ></v-text-field>
              <v-switch
                v-if="source.column === constant.shippingInstructionEntryColumns.IS_READY"
                v-model="source.value"
                inset
                dense
              ></v-switch>
            </v-col>
            <v-col cols="1"
              ><v-btn v-if="index != 0" x-small fab dense @click="onDelSettingSource(index)">
                <v-icon dark> mdi-minus </v-icon></v-btn
              ></v-col
            >
          </v-row>
          <v-row dense>
            <v-col cols="12">
              <v-btn x-small fab dense @click="onAddSettingSource">
                <v-icon dark> mdi-plus </v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <v-divider class="mt-3 pt-3"></v-divider>
          <v-row dense>
            <v-col cols="12" sm="12">
              <v-spacer></v-spacer>
              <tooltip-icon-button :disabled="!selectedRows" icon="mdi-arrow-down-bold-outline" @click="onBtnSetUpdate"
                >出荷指示反映</tooltip-icon-button
              >
            </v-col>
            <v-col cols="12" sm="12" style="height: 450px">
              <ag-grid-vue
                class="ag-theme-alpine"
                :defaultColDef="defaultColDef"
                :columnDefs="columnDefs"
                :alwaysShowHorizontalScroll="true"
                :suppressColumnVirtualisation="true"
                :enableCellTextSelection="true"
                :pagination="true"
                paginationPageSize="10"
                :localeText="localeText"
                rowSelection="multiple"
                @grid-ready="onGridReady"
                @selection-changed="onSelectionChanged"
                @column-everything-changed="onColumnEverythingChanged"
                :columnTypes="columnTypes"
                :getRowNodeId="(data) => data.shippingInstructionId"
                :frameworkComponents="frameworkComponents"
                @cell-value-changed="onCellValueChanged"
                @cell-editing-started="onCellEditingStarted"
                style="height: 100%"
              >
              </ag-grid-vue>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions>
      <v-btn color="secondary" @click="onCancelClick">キャンセル</v-btn>
      <v-spacer></v-spacer>
      <v-btn color="primary" @click="onUpdateClick">更新</v-btn>
    </v-card-actions>
    <v-overlay :value="isLoading">
      <v-progress-circular indeterminate color="primary" size="64"></v-progress-circular>
    </v-overlay>
    <error-grid-dialog
      ref="updateErrorGrid"
      width="80%"
      height="80%"
      icon=""
      title="出荷指示編集"
      btnSubmit="登録"
      :columns="errorColmuns"
    ></error-grid-dialog>
  </v-card>
</template>

<script>
import { AgGridVue } from "ag-grid-vue";
import { AG_GRID_LOCALE_JA } from "../../models/ag-grid/locales";
import {
  NumericColumn,
  PercentColumn,
  DateColumn,
  FullDateColumn,
  CheckmarkColumn,
  EditableColumn,
  EditableDateColumn,
  EditableCheckBoxColumn,
  EditableCheckmarkColumn,
} from "../../models/ag-grid/columnTypes";
import CafereoShippingStatuses from "../../consts/shippingStatus/CafereoShippingStatuses";
import ShippingInstructionEntryColumns from "../../consts/editColumns/ShippingInstructionEntryColumns";
import TooltipIconButton from "../common/TooltipIconButton.vue";
import { DateCellEditor, CheckboxCellEditor, CustomDateCellEditor } from "../../components/ag-grid/cellEditors";
import ErrorGridDialog from "../../components/common/ErrorGridDialog.vue";
import { statuses as ApiStatus } from "../../libs/api-client";
import Validation from "../../libs/validation";
import moment from "moment";
import { DateValueFormatter } from "../../models/ag-grid/valueFormatters";
import {} from "../../components/ag-grid/cellEditors";
import { BooleanFilter, SelectionFilter } from "../../components/ag-grid/filters";

export default {
  name: "ShippingInstructionEntry",
  props: ["inputModel"],
  components: {
    AgGridVue,
    TooltipIconButton,
    ErrorGridDialog,
    /* eslint-disable vue/no-unused-components */
    CustomDateCellEditor,
  },
  data() {
    return {
      updateModel: {},
      defaultModel: {
        shipDate: null,
        specifiedDeliveryDate: null,
        isReady: null,
        cartonNumber: null,
        useSagawa: null,
        useCharter: null,
        priorityAm: null,
        useOricon: null,
        gachaProducts: null,
        bundledDesignation: null,
        preShipment: null,
        cashOnDelivery: null,
        useShippingLabel: null,
        enhancedInspection: null,
        //remarks: null,
        slipRemarks: null,
      },
      defaultColDef: null,
      columnDefs: null,
      gridApi: null,
      columnApi: null,
      selectedRows: null,
      domLayout: null,
      localeText: AG_GRID_LOCALE_JA,
      updateList: [],
      settingSources: [],
      constant: {
        shippingInstructionEntryColumnsValues: ShippingInstructionEntryColumns.all(),
        shippingInstructionEntryColumns: ShippingInstructionEntryColumns,
      },
      columnTypes: {
        dpNumericColumn: NumericColumn,
        dpPercentColumn: PercentColumn,
        dpDateColumn: DateColumn,
        dpFullDateColumn: FullDateColumn,
        dpCheckmarkColumn: CheckmarkColumn,
        dpEditableCheckmarkColumn: EditableCheckmarkColumn,
        dpEditableColumn: EditableColumn,
        dpEditableDateColumn: EditableDateColumn,
        dpEditableCheckBoxColumn: EditableCheckBoxColumn,
      },
      frameworkComponents: {
        dpDateCellEditor: DateCellEditor,
        dpCheckboxCellEditor: CheckboxCellEditor,
        dpBooleanFilter: BooleanFilter,
        dpSelectionFilter: SelectionFilter,
        dpCellDatePicker: CustomDateCellEditor,
      },
      errorColmuns: [
        { headerName: "出荷ID", field: "shippingInstructionId" },
        {
          headerName: "エラー内容",
          field: "errorMessage",
          wrapText: true,
          autoHeight: true,
          cellRenderer: function (param) {
            return param.data.errorMessage.join("<br>");
          },
        },
      ],
      errorRows: [],
      updateSuccessRecords: [],
      rules: {
        required: Validation.required,
        isNumber: Validation.isNumber,
        maxLength: Validation.maxLength,
        isFileName: Validation.isFileName,
        validSelectColumn: (value) => this.validSelectColumn(value),
      },
    };
  },
  watch: {
    inputModel(inputModel) {
      this.init(inputModel);
    },
  },
  computed: {
    isLoading() {
      return this.$store.getters["ui/isLoading"];
    },
  },
  methods: {
    onGridReady(params) {
      this.gridApi = params.api;
      this.columnApi = params.columnApi;

      this.init(this.inputModel);

      var allColumnIds = [];
      this.columnApi.getAllColumns().forEach(function (column) {
        allColumnIds.push(column.colId);
      });
      this.columnApi.autoSizeColumns(allColumnIds);
      this.gridApi.refreshCells();
    },
    onSelectionChanged() {
      this.selectedRows = this.gridApi.getSelectedRows();
    },
    onColumnEverythingChanged() {
      var allColumnIds = [];
      this.columnApi?.getAllColumns().forEach(function (column) {
        allColumnIds.push(column.colId);
      });
      this.columnApi?.autoSizeColumns(allColumnIds);
      this.gridApi?.refreshCells();
    },
    onBtnSetUpdate() {
      if (this.validate()) {
        this.selectedRows.forEach((row) => {
          this.settingSources.forEach((source) => {
            if (source.column === this.constant.shippingInstructionEntryColumns.SHIP_DATE) {
              row.shipDate = source.value ?? row.shipDate;
              row.updateShipDate = true;
            } else if (source.column === this.constant.shippingInstructionEntryColumns.SPECIFIED_DELIVERY_DATE) {
              row.specifiedDeliveryDate = source.value ?? row.specifiedDeliveryDate;
              row.updateSpecifiedDeliveryDate = true;
            } else if (source.column === this.constant.shippingInstructionEntryColumns.CARTON_NUMBER) {
              row.cartonNumber = source.value;
              row.updateCartonNumber = true;
            } else if (source.column === this.constant.shippingInstructionEntryColumns.USE_SAGAWA) {
              row.useSagawa = source.value;
              row.updateUseSagawa = true;
            } else if (source.column === this.constant.shippingInstructionEntryColumns.USE_CHARTER) {
              row.useCharter = source.value;
              row.updateUseCharter = true;
            } else if (source.column === this.constant.shippingInstructionEntryColumns.PRIORITY_AM) {
              row.priorityAm = source.value;
              row.updatePriorityAm = true;
            } else if (source.column === this.constant.shippingInstructionEntryColumns.USE_ORICON) {
              row.useOricon = source.value;
              row.updateUseOricon = true;
            } else if (source.column === this.constant.shippingInstructionEntryColumns.GACHA_PRODUCTS) {
              row.gachaProducts = source.value;
              row.updateGachaProducts = true;
            } else if (source.column === this.constant.shippingInstructionEntryColumns.BUNDLED_DESIGNATION) {
              row.bundledDesignation = source.value;
              row.updateBundledDesignation = true;
            } else if (source.column === this.constant.shippingInstructionEntryColumns.PRE_SHIPMENT) {
              row.preShipment = source.value;
              row.updatePreShipment = true;
            } else if (source.column === this.constant.shippingInstructionEntryColumns.CASH_ON_DELIVERY) {
              row.cashOnDelivery = source.value;
              row.updateCashOnDelivery = true;
            } else if (source.column === this.constant.shippingInstructionEntryColumns.USE_SHIPPING_LABEL) {
              row.useShippingLabel = source.value;
              row.updateUseShippingLabel = true;
            } else if (source.column === this.constant.shippingInstructionEntryColumns.REMARKS) {
              row.remarks = source.value;
              row.updateRemarks = true;
            } else if (source.column === this.constant.shippingInstructionEntryColumns.SLIP_REMARKS) {
              row.slipRemarks = source.value;
              row.updateSlipRemarks = true;
            } else if (source.column === this.constant.shippingInstructionEntryColumns.IS_READY) {
              row.isReady = source.value;
              row.updateIsReady = true;
            }
          });
        });
        this.gridApi.applyTransaction({ update: this.selectedRows });
        this.updateModel = this.defaultModel;
      }
    },
    init(inputModel) {
      this.updateModel = this.defaultModel;
      this.gridApi.setRowData(JSON.parse(JSON.stringify(inputModel)));
      this.updateSuccessRecords = [];
      // 初期表示時、設定項目を１つ表示する。
      this.onAddSettingSource();
      if (this.$refs.shippingInstructionEntryForm) {
        this.$refs.shippingInstructionEntryForm.resetValidation();
      }
    },
    reset() {
      this.init(this.inputModel);
      this.updateSuccessRecords = [];
    },
    onCancelClick() {
      this.$emit("cancel", this.updateSuccessRecords);
    },
    async onUpdateClick() {
      var requestRecords = [];
      this.errorRows = [];
      var isValid = true;
      console.log(this.selectedRows);
      this.gridApi.forEachNode((record) => {
        console.log(record);
        var error = this.validateRow(record.data);
        if (error != true) {
          this.errorRows.push({
            shippingInstructionId: record.data.shippingInstructionId,
            errorMessage: error,
          });
          isValid = false;
        } else {
          requestRecords.push(this.requestFormat(record.data));
        }
      });
      if (isValid) {
        try {
          this.loadingOn();
          // バッチ化のためListを直接送信するよう変更
          // var param = {
          //   shipments: requestRecords,
          // };
          const response = await this.$store.dispatch("shippingInstruction/update", requestRecords);
          let error = response.data?.header;
          // 一覧更新レコード
          let updateRows = [];
          this.errorRows = [];
          switch (error.resultCode) {
            case ApiStatus.consts.SUCCESS:
            case ApiStatus.consts.BUSINESS_ERROR:
            case ApiStatus.consts.ALREADY_CHANGED:
              // エラーメッセージ格納
              if (error.messages) {
                Object.keys(error.messages).forEach((key) => {
                  this.errorRows.push({
                    shippingInstructionId: key,
                    errorMessage: error.messages[key],
                  });
                });
              }
              // 一覧部の更新分のレコード設定
              if (response.data.contents) {
                this.gridApi.forEachNode((row) => {
                  response.data.contents.shipments.forEach((shipment) => {
                    let isError = this.errorRows.some(
                      (r) => r.shippingInstructionId === row.data.shippingInstructionId
                    );
                    if (shipment.shippingInstructionId == row.data.shippingInstructionId && !isError) {
                      this.updateSuccessRecords.push(shipment);
                      updateRows.push(shipment);
                    }
                  });
                });
              }
              // エラー表示
              if (this.errorRows.length > 0) {
                // 確認ダイアログから更新した分を削除(エラーがある場合のみ)
                this.gridApi.applyTransaction({ remove: updateRows });
                this.$refs.updateErrorGrid.open({ records: this.errorRows });
                this.$dialog.notify.error(`出荷指示の更新処理に失敗したデータが存在します。ご確認ください。`, {
                  timeout: 2300,
                });
              } else {
                this.$dialog.notify.info(`出荷指示を変更しました`, { timeout: 2300 });
                this.$refs.updateErrorGrid.close();
                this.$emit("updated", this.updateSuccessRecords);
                this.reset();
              }
              break;
            default:
              this.redirectError();
              break;
          }
        } catch (error) {
          console.error("ArrivalAchievementImport::onSubmit", error);
          this.apiRequestError(error);
        } finally {
          this.loadingOff();
        }
      } else {
        this.$refs.updateErrorGrid.open({ records: this.errorRows });
        this.$dialog.notify.error(`更新データに入力エラーが存在します。ご確認ください。`, {
          timeout: 2300,
        });
      }
    },
    validate() {
      const isValid = this.$refs.shippingInstructionEntryForm.validate();
      if (!isValid) {
        this.$dialog.notify.error(`入力エラーがあります`, { timeout: 2300 });
      }
      return isValid;
    },
    validateRow(row) {
      var ret = true;
      var messages = [];
      // 出荷日
      this.setValidMessage(this.rules.required(row.shipDate), "出荷日", messages);
      this.setValidMessage(this.isDate(row.shipDate), "出荷日", messages);
      this.setValidMessage(this.isShipDateRule(row.shipDate), "出荷日", messages);
      // 配送指定日
      this.setValidMessage(this.rules.required(row.specifiedDeliveryDate), "配送指定日", messages);
      this.setValidMessage(this.isDate(row.specifiedDeliveryDate), "配送指定日", messages);
      this.setValidMessage(
        this.isSpecifiedDeliveryDateRule(row.specifiedDeliveryDate, row.shipDate),
        "配送指定日",
        messages
      );
      // カートン分割指定
      this.setValidMessage(this.rules.maxLength(30)(row.cartonNumber), "カートン分割指定", messages);
      this.setValidMessage(this.rules.isFileName(row.cartonNumber), "カートン分割指定", messages);
      //// 備考
      //this.setValidMessage(this.rules.maxLength(30)(row.remarks), "備考", messages);
      // 伝票備考
      this.setValidMessage(this.rules.maxLength(30)(row.slipRemarks), "伝票備考", messages);
      if (messages.length > 0) ret = messages;
      return ret;
    },
    isDate(value) {
      if (value == null || value == "") return true;
      if (!moment(value, "YYYY-MM-DD", true).isValid()) return "YYYY/MM/DD形式で入力してください";
      return true;
    },
    isShipDateRule(value) {
      if (value == null) return true;
      if (!moment(value).isSameOrAfter(moment().format("YYYY-MM-DD"))) return "現在日以降を入力してください";
      return true;
    },
    isSpecifiedDeliveryDateRule(value, compareValue) {
      if (value == null || compareValue == null) return true;
      if (!moment(value).isSameOrAfter(moment(compareValue))) return "出荷日以降を入力してください";
      return true;
    },
    maxLength(value, length) {
      if (value == null || value == "") return true;
      var ret = false;
      if (typeof value == "number") {
        if (String(value).length <= length) {
          ret = true;
        }
      } else {
        if (value.length <= length) {
          ret = true;
        }
      }
      return ret;
    },
    requestFormat(row) {
      return {
        shippingInstructionId: row.shippingInstructionId,
        shipDate: row.shipDate,
        specifiedDeliveryDate: row.specifiedDeliveryDate,
        useSagawa: Boolean(row.useSagawa),
        useCharter: Boolean(row.useCharter),
        priorityAm: Boolean(row.priorityAm),
        useOricon: Boolean(row.useOricon),
        gachaProducts: Boolean(row.gachaProducts),
        bundledDesignation: Boolean(row.bundledDesignation),
        preShipment: Boolean(row.preShipment),
        cashOnDelivery: Boolean(row.cashOnDelivery),
        useShippingLabel: Boolean(row.useShippingLabel),
        cartonNumber: row.cartonNumber,
        remarks: row.remarks,
        slipRemarks: row.slipRemarks,
        isReady: Boolean(row.isReady),
        updateDatetime: row.updateDatetime,
      };
    },
    setValidMessage(check, culumnName, messages) {
      if (!(check === true)) messages.push(culumnName + "は" + check);
    },
    onCellEditingStarted(event) {
      console.log("cellEditingStarted " + event.column.colId);
      const popupTop = event.api.getCellEditorInstances()[0].getGui().style.top;
      event.api.getCellEditorInstances()[0].getGui().style.top = parseInt(popupTop) - 40 + "px";
    },
    onCellValueChanged(params) {
      console.log(params);
      if (params.colDef.field == "shipDate") {
        params.data.updateShipDate = true;
      } else if (params.colDef.field == "specifiedDeliveryDate") {
        params.data.updateSpecifiedDeliveryDate = true;
      } else if (params.colDef.field == "isReady") {
        params.data.updateIsReady = true;
      } else if (params.colDef.field == "cartonNumber") {
        params.data.updateCartonNumber = true;
      } else if (params.colDef.field == "useSagawa") {
        params.data.updateUseSagawa = true;
      } else if (params.colDef.field == "useCharter") {
        params.data.updateUseCharter = true;
      } else if (params.colDef.field == "priorityAm") {
        params.data.updatePriorityAm = true;
      } else if (params.colDef.field == "useOricon") {
        params.data.updateUseOricon = true;
      } else if (params.colDef.field == "gachaProducts") {
        params.data.updateGachaProducts = true;
      } else if (params.colDef.field == "bundledDesignation") {
        params.data.updateBundledDesignation = true;
      } else if (params.colDef.field == "preShipment") {
        params.data.updatePreShipment = true;
      } else if (params.colDef.field == "cashOnDelivery") {
        params.data.updateCashOnDelivery = true;
      } else if (params.colDef.field == "useShippingLabel") {
        params.data.updateUseShippingLabel = true;
      } else if (params.colDef.field == "remarks") {
        params.data.updateRemarks = true;
      } else if (params.colDef.field == "slipRemarks") {
        params.data.updateSlipRemarks = true;
      }
      let rows = [];
      rows.push(params.node);
      this.gridApi.refreshCells({ force: true, rowNodes: rows });
    },
    onSettingColSelected(index) {
      this.settingSources[index].value = "";
    },
    onAddSettingSource() {
      let settingSource = {
        column: 0,
        value: "",
      };
      this.settingSources.push(settingSource);
    },
    onDelSettingSource(index) {
      this.settingSources.splice(index, 1);
    },
    validSelectColumn(value) {
      if (value == null) return true;
      if (this.settingSources.filter((settingSource) => settingSource.column === value).length > 1)
        return "同一項目が選択されています。";
      return true;
    },
  },
  beforeMount() {
    this.domLayout = "autoHeight";
    this.defaultColDef = {
      filter: "agTextColumnFilter",
      resizable: true,
      sortable: true,
      suppressSizeToFit: true,
      filterParams: {
        newRowsAction: "keep",
      },
    };
    this.columnDefs = [
      {
        headerName: "",
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true,
        checkboxSelection: true,
        filter: false,
        resizable: false,
        sortable: false,
        pinned: "left",
      },
      {
        headerName: "伝票番号",
        field: "slipNo",
        pinned: "left",
      },
      {
        headerName: "受注ID",
        field: "orderId",
      },
      {
        headerName: "JANCODE",
        field: "janCode",
      },
      {
        headerName: "タイトル",
        field: "title",
      },
      {
        headerName: "商品名",
        field: "productName",
      },
      {
        headerName: "出荷日",
        field: "shipDate",
        type: "dpEditableColumn",
        cellEditorFramework: "CustomDateCellEditor",
        valueFormatter: DateValueFormatter("YYYY/MM/DD"),
        cellStyle: (params) => {
          let ret = { backgroundColor: "#FFFFCC" };
          if (params.data.updateShipDate) {
            ret.backgroundColor = "#CCFFCC";
          }
          return ret;
        },
      },
      {
        headerName: "配送指定日",
        field: "specifiedDeliveryDate",
        type: "dpEditableColumn",
        cellEditorFramework: "CustomDateCellEditor",
        valueFormatter: DateValueFormatter("YYYY/MM/DD"),
        cellStyle: (params) => {
          let ret = { backgroundColor: "#FFFFCC" };
          if (params.data.updateSpecifiedDeliveryDate) {
            ret.backgroundColor = "#CCFFCC";
          }
          return ret;
        },
      },
      {
        headerName: "配送先所要日数",
        field: "daysRequired",
        valueGetter: (params) => {
          if (params.data.daysRequired == 1) {
            return "翌日着";
          } else if (params.data.daysRequired > 1) {
            const daysParams = params.data.daysRequired - 1;
            return `中${daysParams}日着`;
          }
          return "";
        },
      },
      {
        headerName: "出荷希望",
        field: "isReady",
        type: "dpEditableCheckmarkColumn",
        cellStyle: (params) => {
          let ret = { backgroundColor: "#FFFFCC" };
          if (params.data.updateIsReady) {
            ret.backgroundColor = "#CCFFCC";
          }
          return ret;
        },
      },
      {
        headerName: "カートン分割指定",
        field: "cartonNumber",
        type: "dpEditableColumn",
        cellStyle: (params) => {
          let ret = { backgroundColor: "#FFFFCC" };
          if (params.data.updateCartonNumber) {
            ret.backgroundColor = "#CCFFCC";
          }
          return ret;
        },
      },
      {
        headerName: "佐川指定",
        field: "useSagawa",
        type: "dpEditableCheckmarkColumn",
        cellStyle: (params) => {
          let ret = { backgroundColor: "#FFFFCC" };
          if (params.data.updateUseSagawa) {
            ret.backgroundColor = "#CCFFCC";
          }
          return ret;
        },
      },
      {
        headerName: "チャーター指定",
        field: "useCharter",
        type: "dpEditableCheckmarkColumn",
        cellStyle: (params) => {
          let ret = { backgroundColor: "#FFFFCC" };
          if (params.data.updateUseCharter) {
            ret.backgroundColor = "#CCFFCC";
          }
          return ret;
        },
      },
      {
        headerName: "AM優先指定",
        field: "priorityAm",
        type: "dpEditableCheckmarkColumn",
        cellStyle: (params) => {
          let ret = { backgroundColor: "#FFFFCC" };
          if (params.data.updatePriorityAm) {
            ret.backgroundColor = "#CCFFCC";
          }
          return ret;
        },
      },
      {
        headerName: "オリコン指定",
        field: "useOricon",
        type: "dpEditableCheckmarkColumn",
        cellStyle: (params) => {
          let ret = { backgroundColor: "#FFFFCC" };
          if (params.data.updateUseOricon) {
            ret.backgroundColor = "#CCFFCC";
          }
          return ret;
        },
      },
      {
        headerName: "ガチャ商品",
        field: "gachaProducts",
        type: "dpEditableCheckmarkColumn",
        cellStyle: (params) => {
          let ret = { backgroundColor: "#FFFFCC" };
          if (params.data.updateGachaProducts) {
            ret.backgroundColor = "#CCFFCC";
          }
          return ret;
        },
      },
      {
        headerName: "同梱指定",
        field: "bundledDesignation",
        type: "dpEditableCheckmarkColumn",
        cellStyle: (params) => {
          let ret = { backgroundColor: "#FFFFCC" };
          if (params.data.updateBundledDesignation) {
            ret.backgroundColor = "#CCFFCC";
          }
          return ret;
        },
      },
      {
        headerName: "事前出荷指定(カートン出荷)",
        field: "preShipment",
        type: "dpEditableCheckmarkColumn",
        cellStyle: (params) => {
          let ret = { backgroundColor: "#FFFFCC" };
          if (params.data.updatePreShipment) {
            ret.backgroundColor = "#CCFFCC";
          }
          return ret;
        },
      },
      {
        headerName: "着払い出荷",
        field: "cashOnDelivery",
        type: "dpEditableCheckmarkColumn",
        cellStyle: (params) => {
          let ret = { backgroundColor: "#FFFFCC" };
          if (params.data.updateCashOnDelivery) {
            ret.backgroundColor = "#CCFFCC";
          }
          return ret;
        },
      },
      {
        headerName: "要送状NO",
        field: "useShippingLabel",
        type: "dpEditableCheckmarkColumn",
        cellStyle: (params) => {
          let ret = { backgroundColor: "#FFFFCC" };
          if (params.data.updateUseShippingLabel) {
            ret.backgroundColor = "#CCFFCC";
          }
          return ret;
        },
      },
      // {
      //   headerName: "備考",
      //   field: "remarks",
      //   editable: true,
      //   cellStyle: (params) => {
      //     let ret = { backgroundColor: "#FFFFCC" };
      //     if (params.data.updateRemarks) {
      //       ret.backgroundColor = "#CCFFCC";
      //     }
      //     return ret;
      //   },
      // },
      {
        headerName: "伝票備考",
        field: "slipRemarks",
        editable: true,
        cellStyle: (params) => {
          let ret = { backgroundColor: "#FFFFCC" };
          if (params.data.updateSlipRemarks) {
            ret.backgroundColor = "#CCFFCC";
          }
          return ret;
        },
      },
      {
        headerName: "出荷ステータス",
        field: "shippingStatus",
        valueGetter: (params) => CafereoShippingStatuses.of(params.data.shippingStatus),
      },
      {
        headerName: "出荷場所",
        field: "storageName",
      },
      {
        headerName: "受注数",
        field: "orderQuantity",
        type: "dpNumericColumn",
      },
      { headerName: "発売日", field: "releaseDate" },
      { headerName: "社内備考", field: "cafereoRemarks" },
      {
        headerName: "配送CD",
        field: "deliveryCode",
      },
      {
        headerName: "配送先住所",
        field: "deliveryAddress",
      },
      {
        headerName: "届先法人名",
        field: "deliveryName1",
      },
      {
        headerName: " 届先名称",
        field: "deliveryName2",
      },
      {
        headerName: "移動出荷指示",
        field: "isMoving",
        type: "dpCheckmarkColumn",
      },
    ];
  },
};
</script>
