<template>
  <v-card>
    <v-card-title>
      <span class="headline"><v-icon>mdi-receipt</v-icon>CR検品確認</span>
    </v-card-title>
    <span></span>
    <v-card-subtitle> {{ recordCount }}件</v-card-subtitle>
    <v-divider></v-divider>
    <v-card-text>
      <v-form>
        <v-container>
          <v-divider></v-divider>
          <v-spacer></v-spacer>
          <v-row dense>
            <v-col cols="12" sm="12" :style="gridStyle">
              <ag-grid-vue
                class="ag-theme-alpine"
                style="height: 100%"
                :defaultColDef="defaultColDef"
                :columnDefs="columnDefs"
                :alwaysShowHorizontalScroll="true"
                :pagination="false"
                :localeText="localeText"
                @grid-ready="onGridReady"
                @selection-changed="onSelectionChanged"
                :columnTypes="columnTypes"
                :getRowNodeId="(data) => data.shippingInstructionId"
                :frameworkComponents="frameworkComponents"
              >
              </ag-grid-vue>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions>
      <v-btn color="secondary" @click="onCancelClick">キャンセル</v-btn>
      <v-spacer></v-spacer>
      <v-btn color="primary" @click="onConfirmClick">確定</v-btn>
    </v-card-actions>
    <v-overlay :value="isLoading">
      <v-progress-circular indeterminate color="primary" size="64"></v-progress-circular>
    </v-overlay>
    <error-grid-dialog
      ref="updateErrorGrid"
      width="80%"
      height="80%"
      title="CR検品確認"
      btnSubmit="登録"
      :columns="errorColmuns"
    ></error-grid-dialog>
  </v-card>
</template>

<script>
import { AgGridVue } from "ag-grid-vue";
import { AG_GRID_LOCALE_JA } from "../../models/ag-grid/locales";
import { NumericColumn, PercentColumn, DateColumn, CheckmarkColumn } from "../../models/ag-grid/columnTypes";
import { statuses as ApiStatus } from "../../libs/api-client";
import ErrorGridDialog from "../../components/common/ErrorGridDialog.vue";

export default {
  name: "CafereoInspected",
  props: ["inputModel"],
  components: {
    AgGridVue,
    ErrorGridDialog,
  },
  data() {
    return {
      updateModel: {},
      gridStyle: { height: "95%" },
      defaultModel: {
        goodsReceiptNumber: null,
      },
      defaultColDef: null,
      columnDefs: null,
      gridApi: null,
      columnApi: null,
      selectedRows: null,
      domLayout: null,
      localeText: AG_GRID_LOCALE_JA,
      updateList: [],
      columnTypes: {
        dpNumericColumn: NumericColumn,
        dpPercentColumn: PercentColumn,
        dpDateColumn: DateColumn,
        dpCheckmarkColumn: CheckmarkColumn,
      },
      frameworkComponents: {},
      errorColmuns: [
        { headerName: "出荷ID", field: "shippingInstructionId" },
        {
          headerName: "エラー内容",
          field: "errorMessage",
          wrapText: true,
          autoHeight: true,
          cellRenderer: function (param) {
            return param.data.errorMessage.join("<br>");
          },
        },
      ],
      errorRows: [],
      recordCount: 0,
      updateSuccessRecords: [],
    };
  },
  mounted() {
    this.handleResize();
    // 画面解像度による画面サイズ取得
    this.gridStyle.height = this.gridHeightSize - 150 + "px";
    window.addEventListener("resize", this.handleResize);
  },
  beforeMount() {
    this.domLayout = "autoHeight";
    this.defaultColDef = {
      filter: "agTextColumnFilter",
      resizable: true,
      sortable: true,
      suppressSizeToFit: true,
      filterParams: {
        newRowsAction: "keep",
      },
    };
    this.columnDefs = [
      {
        headerName: "",
        headerCheckboxSelection: false,
        headerCheckboxSelectionFilteredOnly: true,
        checkboxSelection: false,
        filter: false,
        resizable: false,
        sortable: false,
        pinned: "left",
      },
      { headerName: "出荷日", field: "shipDate", type: "dpDateColumn", pinned: "left" },
      { headerName: "伝票日付", field: "slipDate", type: "dpDateColumn", pinned: "left" },
      { headerName: "伝票番号", field: "slipNo", pinned: "left" },
      { headerName: "社店CD", field: "companyStoreCode" },
      { headerName: "法人名", field: "companyName" },
      { headerName: "取引先名", field: "storeName" },
      { headerName: "JANCODE", field: "janCode" },
      {
        headerName: "仕入先略称",
        field: "supplierName",
      },
      { headerName: "タイトル", field: "title" },
      { headerName: "商品名", field: "productName" },
      { headerName: "上代", field: "retailPrice", type: "dpNumericColumn" },
      {
        headerName: "仕入掛率",
        field: "wholesaleRate",
        type: "dpPercentColumn",
      },
      { headerName: "卸単価", field: "wholesaleUnitPrice", type: "dpNumericColumn" },
      { headerName: "出荷数", field: "shipQuantity", type: "dpNumericColumn" },
      { headerName: "単価合計金額", field: "purchaseTotalPrice", type: "dpNumericColumn" },
      {
        headerName: "粗利率",
        field: "grossProfitMargin",
        type: "dpPercentColumn",
      },
      { headerName: "粗利金額", field: "grossProfitPrice", type: "dpNumericColumn" },
      { headerName: "粗利合計", field: "grossProfitTotalPrice", type: "dpNumericColumn" },
      { headerName: "営業担当者", field: "salesStaff" },
      { headerName: "配送CD", field: "shipCode" },
      { headerName: "届先部署名", field: "shipDepartmentName" },
      { headerName: "届先名", field: "shipName" },
      { headerName: "発生元番号", field: "originalNumber" },
      { headerName: "アニメイト用伝票日付", field: "animateSlipDate", type: "dpDateColumn" },
      { headerName: "在庫保管場所", field: "storageName" },
      { headerName: "物品受領番号", field: "goodsReceiptNumber" },
      { headerName: "一括取込番号", field: "importNumber" },
      { headerName: "届先住所", field: "shipAddress" },
      { headerName: "送状発行日", field: "deliveryInvoiceIssuanceDate", type: "dpDateColumn" },
      { headerName: "送状番号", field: "deliveryInvoiceNumber" },
      { headerName: "出荷ID", field: "shippingInstructionId", hide: true },
      { headerName: "更新日時", field: "updateDatetime", hide: true },
    ];
    this.init(this.inputModel);
  },
  watch: {
    inputModel(inputModel) {
      this.init(inputModel);
    },
    gridHeightSize(value) {
      this.gridStyle.height = value - 150 + "px";
    },
  },
  computed: {
    isLoading() {
      return this.$store.getters["ui/isLoading"];
    },
  },
  methods: {
    onGridReady(params) {
      this.gridApi = params.api;
      this.columnApi = params.columnApi;

      this.init(this.inputModel);

      var allColumnIds = [];
      this.columnApi.getAllColumns().forEach(function (column) {
        allColumnIds.push(column.colId);
      });
      this.columnApi.autoSizeColumns(allColumnIds);
      this.gridApi.refreshCells();
    },
    onSelectionChanged() {
      this.selectedRows = this.gridApi.getSelectedRows();
    },
    onBtnSetUpdate() {
      this.selectedRows.forEach((row) => {
        row.goodsReceiptNumber = this.updateModel.goodsReceiptNumber;
      });
      this.gridApi.applyTransaction({ update: this.selectedRows });
      this.updateModel = this.defaultModel;
    },
    reset() {
      this.init(this.inputModel);
    },
    init(inputModel) {
      this.recordCount = inputModel.length;
      this.updateModel = this.defaultModel;
      this.updateSuccessRecords = [];
      if (this.gridApi) this.gridApi.setRowData(inputModel);
    },
    onCancelClick() {
      this.$emit("cancel", this.updateSuccessRecords);
    },
    async onConfirmClick() {
      this.errorRows = [];
      let shipment = [];
      try {
        this.loadingOn();
        this.inputModel.forEach((elem) => {
          shipment.push({ shippingInstructionId: elem.shippingInstructionId, updateDatetime: elem.updateDatetime });
        });
        const response = await this.$store.dispatch("shipmentAchievement/inspect", { shipments: shipment });
        let error = response.data?.header;
        console.log("CafereoInspected::onConfirmClick", response);
        let updateRows = [];
        this.errorRows = [];
        switch (error.resultCode) {
          case ApiStatus.consts.SUCCESS:
          case ApiStatus.consts.BUSINESS_ERROR:
          case ApiStatus.consts.ALREADY_CHANGED:
            // エラーメッセージ格納
            if (error.messages) {
              Object.keys(error.messages).forEach((key) => {
                this.errorRows.push({
                  shippingInstructionId: key,
                  errorMessage: error.messages[key],
                });
              });
            }
            // 一覧部の更新分のレコード設定
            if (response.data.contents) {
              this.gridApi.forEachNode((row) => {
                response.data.contents.shipmentAchievements.forEach((shipment) => {
                  let isError = this.errorRows.some((r) => r.shippingInstructionId === row.data.shippingInstructionId);
                  if (shipment.shippingInstructionId == row.data.shippingInstructionId && !isError) {
                    this.updateSuccessRecords.push(shipment);
                    updateRows.push(shipment);
                  }
                });
              });
            }
            // エラー表示
            if (this.errorRows.length > 0) {
              // 確認ダイアログから更新した分を削除(エラーがある場合のみ)
              this.gridApi.applyTransaction({ remove: updateRows });
              this.$refs.updateErrorGrid.open({ records: this.errorRows });
              this.$dialog.notify.error(`CR検品処理に失敗したデータが存在します。ご確認ください。`, {
                timeout: 2300,
              });
            } else {
              this.$dialog.notify.info("在庫の移動を確定しました。");
              this.$refs.updateErrorGrid.close();
              this.$emit("updated", this.updateSuccessRecords);
              this.reset();
            }
            break;
          default:
            this.redirectError();
            break;
        }
      } catch (error) {
        console.error("CafereoInspected::onConfirmClick", error);
        this.apiRequestError(error);
      } finally {
        this.loadingOff();
      }
    },
    setError(responseMessage) {
      var messages = [];
      var message = "";
      if (this.isCreation) {
        var errorRows = [];
        // エラー行の行番号を取得
        Object.keys(responseMessage).forEach(function (key) {
          errorRows.push(Number(key));
        });
        // エラー行の行データのみ取得
        var newRowData = this.inputModel.filter((row) => {
          return errorRows.includes(row.number);
        });
        // エラー行の行番号を初期化しエラーメッセージに初期化後の行番号を設定
        newRowData.forEach((row, index) => {
          responseMessage[row.number].forEach((Message) => {
            messages.push(index + 1 + ":" + Message);
          });
          row.number = index + 1;
        });
        this.rowData = newRowData;
        this.gridOptions.api.setRowData(this.inputModel);
      } else {
        if (responseMessage?.NotItem) {
          messages.push(responseMessage.NotItem?.join("<br>"));
        }
      }
      if (messages.length > 0) message = messages.join("<br>");
      return message;
    },
  },
};
</script>
