<template>
  <v-card>
    <v-card-title>
      <span class="headline"><v-icon>mdi-transfer</v-icon>在庫移管</span>
    </v-card-title>
    <v-divider></v-divider>
    <v-card-text>
      <v-form ref="transferForm" v-model="validTransferForm" lazy-validation>
        <v-container>
          <v-row dense>
            <v-col cols="12" sm="3">
              <v-text-field
                label="JANCODE"
                :value="transferModel.janCode"
                filled
                dense
                hide-details
                disabled
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="9">
              <v-text-field
                label="在庫保管場所"
                :value="transferModel.currentStorageName"
                filled
                dense
                hide-details
                disabled
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="9">
              <v-text-field
                label="タイトル"
                :value="transferModel.title"
                filled
                dense
                hide-details
                disabled
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="9">
              <v-text-field
                label="商品名"
                :value="transferModel.productName"
                filled
                dense
                hide-details
                disabled
              ></v-text-field>
            </v-col>
          </v-row>
          <v-divider class="my-3"></v-divider>
          <v-row dense>
            <v-col cols="4" sm="4">
              <v-select
                label="移管元"
                v-model="transferModel.transferSource"
                :items="stockTypeList"
                filled
                dense
                :rules="[rules.required, rules.transferSource]"
                attach
                @change="changeTransferSource"
                ref="transferSource"
              ></v-select>
            </v-col>
            <v-col cols="4" sm="4">
              <v-select
                label="移管先"
                v-model="transferModel.transferDestination"
                :items="stockTypeList"
                filled
                dense
                :rules="[rules.required, rules.transferDestination]"
                attach
                @change="changeTransferDestination"
                ref="transferDestination"
              ></v-select>
            </v-col>
            <v-col cols="4" sm="4">
              <v-text-field
                label="移管数"
                v-model="transferModel.movingQuantity"
                filled
                dense
                :rules="[rules.required, rules.isNumber, rules.maxLength(8), rules.movingQuantity]"
                @blur="changeMovingQuantity"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="4" sm="4">
              <v-text-field
                label="変更前移管元在庫数"
                v-model="transferModel.beforeTransferSourceStockQuantity"
                filled
                dense
                disabled
              ></v-text-field>
            </v-col>
            <v-col cols="4" sm="4">
              <v-text-field
                label="変更後移管元在庫数"
                v-model="transferModel.AfterTransferSourceStockQuantity"
                filled
                dense
                disabled
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="4" sm="4">
              <v-text-field
                label="変更前移管先在庫数"
                v-model="transferModel.beforeTransferDestinationStockQuantity"
                filled
                dense
                disabled
              ></v-text-field>
            </v-col>
            <v-col cols="4" sm="4">
              <v-text-field
                label="変更後移管先在庫数"
                v-model="transferModel.AfterTransferDestinationStockQuantity"
                filled
                dense
                disabled
              ></v-text-field>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions>
      <v-btn color="secondary" @click="onCancelClick">キャンセル</v-btn>
      <v-spacer></v-spacer>
      <v-btn color="primary" @click="onTransferClick">更新</v-btn>
    </v-card-actions>
    <v-overlay :value="isLoading">
      <v-progress-circular indeterminate color="primary" size="64"></v-progress-circular>
    </v-overlay>
  </v-card>
</template>

<script>
import { statuses as ApiStatus } from "../../libs/api-client";
import Validation from "../../libs/validation";
import StockTypes from "../../consts/StockTypes";

export default {
  name: "BadStockMoveEdit",
  props: ["inputModel"],
  data() {
    return {
      columnDefs: null,
      transferModel: null,
      validTransferForm: null,
      rules: {
        required: Validation.required,
        maxLength: Validation.maxLength,
        isNumber: Validation.isNumber,
        movingQuantity: (value) => this.movingQuantityRules(value),
        movingStorageCode: (value) => this.movingStorageCodeRules(value),
        transferSource: (value) => this.transferSourceRules(value),
        transferDestination: (value) => this.transferDestinationRules(value),
      },
      stockTypeList: StockTypes.all(),
    };
  },
  beforeMount() {
    this.init(this.inputModel);
  },
  watch: {
    inputModel(inputModel) {
      this.init(inputModel);
    },
  },
  computed: {
    isLoading() {
      return this.$store.getters["ui/isLoading"];
    },
  },
  methods: {
    async init(inputModel) {
      this.initTransferModel(inputModel);
    },
    initTransferModel(inputModel) {
      this.transferModel = {
        janCode: inputModel.janCode,
        currentStorageName: inputModel.storageName,
        storageCode: inputModel.storageCode,
        title: inputModel.title,
        productName: inputModel.productName,
        currentStockQuantity: inputModel.stockQuantity,
        currentBadQuantity: inputModel.badQuantity,
        transferSource: StockTypes.NOMAL,
        transferDestination: StockTypes.BAD,
        movingQuantity: 0,
        beforeTransferSourceStockQuantity: inputModel.stockQuantity,
        AfterTransferSourceStockQuantity: inputModel.stockQuantity,
        beforeTransferDestinationStockQuantity: inputModel.badQuantity,
        AfterTransferDestinationStockQuantity: inputModel.badQuantity,
        lastUpdateDatetime: inputModel.updateDatetime,
        stockId: inputModel.stockId,
      };
    },
    reset() {
      this.init(this.inputModel);
    },
    onCancelClick() {
      this.$emit("cancel");
    },
    async onTransferClick() {
      if (this.validate()) {
        try {
          this.loadingOn();
          const transferModel = {
            janCode: this.transferModel.janCode,
            storageCode: this.transferModel.storageCode,
            transferSource: Number(this.transferModel.transferSource),
            transferDestination: Number(this.transferModel.transferDestination),
            movingQuantity: Number(this.transferModel.movingQuantity),
            updateDatetime: this.transferModel.lastUpdateDatetime,
          };
          const response = await this.$store.dispatch("transfer/badTransfer", transferModel);
          switch (response.data?.header.resultCode) {
            case ApiStatus.consts.SUCCESS:
              this.$dialog.notify.info("在庫の移管が完了しました。", { timeout: 2300 });
              var stockRecord = response.data.contents.stock;
              this.$emit("transfered", stockRecord);
              this.reset();
              break;
            case ApiStatus.consts.BUSINESS_ERROR:
            case ApiStatus.consts.ALREADY_CHANGED:
              var messages = [];
              Object.keys(response.data?.header.messages).forEach(function (key) {
                messages.push(response.data?.header.messages[key]);
              });
              if (messages.length > 0) {
                this.$dialog.warning({
                  title: "在庫移管",
                  text: messages.join("<br>"),
                  actions: ["OK"],
                });
              }
              break;
            default:
              this.redirectError();
              break;
          }
        } catch (error) {
          console.error("StockMoveEntry::onTransferClick", error);
          this.apiRequestError(error);
        } finally {
          this.loadingOff();
        }
      }
    },
    validate() {
      var isValid = this.$refs.transferForm.validate();
      if (!isValid) {
        this.$dialog.notify.error(`入力エラーがあります`, { timeout: 2300 });
      }
      return isValid;
    },
    movingQuantityRules(value) {
      if (value == null) return true;
      if (Number(value) < 1) return "1以上を入力してください";
      if (0 > Number(this.transferModel.beforeTransferSourceStockQuantity) - Number(value))
        return "変更後移管元在庫数が0以上となる値を入力してください";
      return true;
    },
    transferSourceRules(value) {
      if (value == null || value == "") return true;
      if (value == this.transferModel.transferDestination) return "移管先は異なる在庫を選択してください";
      return true;
    },
    transferDestinationRules(value) {
      if (value == null || value == "") return true;
      if (value == this.transferModel.transferSource) return "移管元は異なる在庫を選択してください";
      return true;
    },
    changeTransferSource(value) {
      var baseQuantity = 0;
      if (value == StockTypes.NOMAL) {
        baseQuantity = Number(this.transferModel.currentStockQuantity);
      } else {
        baseQuantity = Number(this.transferModel.currentBadQuantity);
      }
      this.transferModel.beforeTransferSourceStockQuantity = baseQuantity;
      if (Number(this.transferModel.movingQuantity)) {
        this.transferModel.AfterTransferSourceStockQuantity = baseQuantity - Number(this.transferModel.movingQuantity);
      } else {
        this.transferModel.AfterTransferSourceStockQuantity = baseQuantity;
      }
      if (value != this.transferModel.transferDestination) this.$refs.transferDestination.resetValidation();
    },
    changeTransferDestination(value) {
      var baseQuantity = 0;
      if (value == StockTypes.NOMAL) {
        baseQuantity = Number(this.transferModel.currentStockQuantity);
      } else {
        baseQuantity = Number(this.transferModel.currentBadQuantity);
      }
      this.transferModel.beforeTransferDestinationStockQuantity = baseQuantity;
      if (Number(this.transferModel.movingQuantity)) {
        this.transferModel.AfterTransferDestinationStockQuantity =
          baseQuantity + Number(this.transferModel.movingQuantity);
      } else {
        this.transferModel.AfterTransferDestinationStockQuantity = baseQuantity;
      }
      if (value != this.transferModel.transferSource) this.$refs.transferSource.resetValidation();
    },
    changeMovingQuantity() {
      var value = this.transferModel.movingQuantity;
      if (Number(value)) {
        this.transferModel.AfterTransferSourceStockQuantity =
          this.transferModel.beforeTransferSourceStockQuantity - Number(value);
        this.transferModel.AfterTransferDestinationStockQuantity =
          this.transferModel.beforeTransferDestinationStockQuantity + Number(value);
      }
    },
  },
};
</script>
