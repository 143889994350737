<template>
  <v-card>
    <v-card-title>
      <span class="headline"><v-icon>mdi-transfer</v-icon>在庫移動登録</span>
    </v-card-title>
    <v-divider></v-divider>
    <v-card-text>
      <v-form ref="transferForm" v-model="validTransferForm" lazy-validation>
        <v-container>
          <v-row dense>
            <v-col cols="12" sm="3">
              <v-text-field
                label="JANCODE"
                :value="transferModel.janCode"
                filled
                dense
                hide-details
                disabled
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="9">
              <v-text-field
                label="在庫保管場所"
                :value="transferModel.currentStorageName"
                filled
                dense
                hide-details
                disabled
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" sm="9">
              <v-text-field
                label="タイトル"
                :value="transferModel.title"
                filled
                dense
                hide-details
                disabled
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" sm="9">
              <v-text-field
                label="商品名"
                :value="transferModel.productName"
                filled
                dense
                hide-details
                disabled
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" sm="3">
              <v-text-field
                label="現在庫数(良品)"
                :value="transferModel.currentStockQuantity"
                filled
                dense
                hide-details
                disabled
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="3">
              <v-text-field
                label="現在庫数(不良品)"
                :value="transferModel.currentStockBadQuantity"
                filled
                dense
                hide-details
                disabled
              ></v-text-field>
            </v-col>
          </v-row>
          <v-divider class="my-3"></v-divider>
          <v-row dense>
            <v-col cols="12" sm="3">
              <v-text-field
                label="良品移動数量"
                v-model="transferModel.movingQuantity"
                filled
                dense
                :rules="[rules.required, rules.isNumber, rules.maxLength(8), rules.movingQuantity]"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="3">
              <v-text-field
                :readonly="!isCafereoStorege()"
                label="不良品移動数量"
                v-model="transferModel.movingbadQuantity"
                filled
                dense
                :rules="[rules.required, rules.isNumber, rules.maxLength(8), rules.movingbadQuantity]"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" sm="9">
              <v-select
                label="移動先保管場所"
                v-model="transferModel.movingStorageCode"
                :items="storageList"
                filled
                dense
                :rules="[rules.required, rules.movingStorageCode]"
                attach
              ></v-select>
            </v-col>
            <v-col cols="5">
              <dp-date-picker
                type="date"
                label="移動予定日(出荷予定日)"
                v-model="transferModel.movingPlanDate"
                filled
                dense
                :rules="[rules.required]"
              ></dp-date-picker>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions>
      <v-btn color="secondary" @click="onCancelClick">キャンセル</v-btn>
      <v-spacer></v-spacer>
      <v-btn color="primary" @click="onTransferClick">登録</v-btn>
    </v-card-actions>
    <v-overlay :value="isLoading">
      <v-progress-circular indeterminate color="primary" size="64"></v-progress-circular>
    </v-overlay>
  </v-card>
</template>

<script>
import { statuses as ApiStatus } from "../../libs/api-client";
import Validation from "../../libs/validation";
import Storages from "../../consts/Storages";

export default {
  name: "StockEdit",
  props: ["inputModel"],
  data() {
    return {
      columnDefs: null,
      transferModel: null,
      validTransferForm: null,
      rules: {
        required: Validation.required,
        maxLength: Validation.maxLength,
        isNumber: Validation.isNumber,
        movingbadQuantity: (value) => this.movingbadQuantityRules(value),
        movingQuantity: (value) => this.movingQuantityRules(value),
        movingStorageCode: (value) => this.movingStorageCodeRules(value),
      },
      storageList: Storages.all(),
      storages: Storages,
    };
  },
  beforeMount() {
    this.init(this.inputModel);
  },
  watch: {
    inputModel(inputModel) {
      this.init(inputModel);
    },
  },
  computed: {
    isLoading() {
      return this.$store.getters["ui/isLoading"];
    },
  },
  methods: {
    async init(inputModel) {
      this.initTransferModel(inputModel);
    },
    initTransferModel(inputModel) {
      // 現倉庫以外の倉庫情報を取得(デフォルト設定のため)
      var movingStorageCodes = Storages.all().filter((r) => {
        return String(r.value) != inputModel.storageCode;
      });
      this.transferModel = {
        janCode: inputModel.janCode,
        productName: inputModel.productName,
        currentStorageName: inputModel.storageName,
        currentStockQuantity: inputModel.stockQuantity,
        currentStockBadQuantity: inputModel.badQuantity,
        movingStorageName: null,
        movingQuantity: 0,
        movingPlanDate: null,
        storageCode: inputModel.storageCode,
        movingStorageCode: movingStorageCodes.length > 0 ? movingStorageCodes[0].value : null,
        title: inputModel.title,
        movingbadQuantity: 0,
        lastUpdateDatetime: inputModel.updateDatetime,
      };
    },
    reset() {
      this.init(this.inputModel);
    },
    onCancelClick() {
      this.$emit("cancel");
    },
    async onTransferClick() {
      if (this.validate()) {
        try {
          this.loadingOn();
          const transferModel = {
            janCode: this.transferModel.janCode,
            storageCode: this.transferModel.storageCode,
            stockQuantity: Number(this.transferModel.movingQuantity),
            movingbadQuantity: Number(this.transferModel.movingbadQuantity),
            movingStorageCode: String(this.transferModel.movingStorageCode),
            movingPlanDate: this.transferModel.movingPlanDate,
            updateDatetime: this.transferModel.lastUpdateDatetime,
          };
          const response = await this.$store.dispatch("transfer/create", transferModel);
          switch (response.data?.header.resultCode) {
            case ApiStatus.consts.SUCCESS:
              this.$dialog.notify.info("在庫移動の出荷指示が登録されました", { timeout: 2300 });
              var stockRecord = response.data.contents.stock;
              this.$emit("transfered", stockRecord);
              this.reset();
              break;
            case ApiStatus.consts.BUSINESS_ERROR:
            case ApiStatus.consts.ALREADY_CHANGED:
              var messages = [];
              Object.keys(response.data?.header.messages).forEach(function (key) {
                messages.push(response.data?.header.messages[key]);
              });
              if (messages.length > 0) {
                this.$dialog.warning({
                  title: "在庫移動登録",
                  text: messages.join("<br>"),
                  actions: ["OK"],
                });
              }
              break;
            default:
              this.redirectError();
              break;
          }
        } catch (error) {
          console.error("StockMoveEntry::onTransferClick", error);
          this.apiRequestError(error);
        } finally {
          this.loadingOff();
        }
      }
    },
    validate() {
      var isValid = this.$refs.transferForm.validate();
      if (!isValid) {
        this.$dialog.notify.error(`入力エラーがあります`, { timeout: 2300 });
      }
      return isValid;
    },
    movingQuantityRules(value) {
      if (value == null) return true;
      if (Number(value) < 0) return "0以上を入力してください";
      if (Number(value) > Number(this.transferModel.currentStockQuantity))
        return "現在庫数(良品)以下を入力してください";
      if (this.isMinimumQuantity()) return "移動数量が指定されていません。１以上を指定してください。";
      return true;
    },
    movingbadQuantityRules(value) {
      if (value == null) return true;
      if (Number(value) < 0) return "0以上を入力してください";
      if (Number(value) > Number(this.transferModel.currentStockBadQuantity))
        return "現在庫数(不良品)以下を入力してください";
      if (this.isMinimumQuantity()) return "移動数量が指定されていません。１以上を指定してください。";
      return true;
    },
    movingStorageCodeRules(value) {
      if (value == null || value == "") return true;
      if (value == this.transferModel.storageCode) return "在庫保管場所とは別の保管場所を入力してください";
      return true;
    },
    isCafereoStorege() {
      const ret = this.transferModel.movingStorageCode == this.storages.CAFEREO;
      if (!ret) this.transferModel.movingbadQuantity = 0;
      return ret;
    },
    isMinimumQuantity() {
      const ret = Number(this.transferModel.movingQuantity) < 1 && Number(this.transferModel.movingbadQuantity) < 1;
      return ret;
    },
  },
};
</script>
