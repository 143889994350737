import { createDefault } from "../../utils/ConstUtils";

export default createDefault([
  { value: 1, text: "出荷日", enum: "SHIP_DATE" },
  { value: 2, text: "配送指定日", enum: "SPECIFIED_DELIVERY_DATE" },
  { value: 3, text: "カートン分割指定", enum: "CARTON_NUMBER" },
  { value: 4, text: "佐川指定", enum: "USE_SAGAWA" },
  { value: 5, text: "チャーター指定", enum: "USE_CHARTER" },
  { value: 6, text: "AM優先指定", enum: "PRIORITY_AM" },
  { value: 7, text: "オリコン指定", enum: "USE_ORICON" },
  { value: 8, text: "ガチャ商品", enum: "GACHA_PRODUCTS" },
  { value: 9, text: "同梱指定", enum: "BUNDLED_DESIGNATION" },
  { value: 10, text: "事前出荷指定(カートン出荷)", enum: "PRE_SHIPMENT" },
  { value: 11, text: "着払い出荷", enum: "CASH_ON_DELIVERY" },
  { value: 12, text: "要送状NO", enum: "USE_SHIPPING_LABEL" },
  //{ value: 13, text: "備考", enum: "REMARKS" },
  { value: 13, text: "伝票備考", enum: "SLIP_REMARKS" },
  { value: 14, text: "出荷希望", enum: "IS_READY" },
]);
