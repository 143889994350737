<template>
  <v-container fluid style="height: 100%">
    <simple-history :histories="histories" :functionCategory="functionCategory"></simple-history>
  </v-container>
</template>

<script>
import SimpleHistory from "../common/SimpleHistory.vue";
import { statuses as ApiStatus } from "../../libs/api-client";
export default {
  name: "ArrivalAchievementHistory",
  props: ["arrivalId"],
  components: {
    SimpleHistory,
  },
  data() {
    return {
      histories: {},
      functionCategory: "",
    };
  },
  watch: {
    arrivalId(arrivalId) {
      this.init(arrivalId);
    },
  },
  mounted() {
    this.init(this.arrivalId);
  },
  methods: {
    async init(arrivalId) {
      try {
        this.loadingOn();
        this.functionCategory = "arrival";
        const response = await this.$store.dispatch("arrivalAchievement/history", { arrivalId: arrivalId });
        if (ApiStatus.isSystemError(response.data?.header)) {
          return this.redirectError();
        }
        this.histories = response.data.contents.histories;
      } catch (error) {
        console.error("OrderHArrivalAchievementHistoryistory::init", error);
      } finally {
        this.loadingOff();
      }
    },
  },
};
</script>
