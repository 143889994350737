<template>
  <v-container fluid style="height: 100%">
    <v-row>
      <v-col cols="12" class="pa-0">
        <v-app-bar dense>
          <v-app-bar-title><v-icon>mdi-clipboard-text-search</v-icon>出荷一覧</v-app-bar-title>
          <v-spacer></v-spacer>
          <v-form ref="searchForm" v-model="validSearchForm" lazy-validation>
            <search-conditions @search="onBtnSearch" max-height="100%">
              <v-row dense>
                <v-col>
                  <v-checkbox label="検索上限無し" v-model="searchModel.fetchLimitOverFlg" dense></v-checkbox>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col>
                  <dp-date-picker
                    type="date"
                    label="出庫日From"
                    v-model="searchModel.shipDateFrom"
                    dense
                    :rules="[rules.shipDateFromRule]"
                  ></dp-date-picker>
                </v-col>
                <v-col>
                  <dp-date-picker
                    type="date"
                    label="出庫日To"
                    v-model="searchModel.shipDateTo"
                    dense
                    :rules="[rules.shipDateToRule]"
                  ></dp-date-picker>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col>
                  <v-textarea
                    label="JANCODE"
                    v-model="searchModel.janCode"
                    :rules="[rules.maxLength(1400), rules.isJancodeSearchList]"
                    rows="1"
                    dense
                  ></v-textarea>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col>
                  <dp-date-picker
                    type="date"
                    label="取込日From"
                    v-model="searchModel.importDateFrom"
                    dense
                    :rules="[rules.importDateFromRule]"
                  ></dp-date-picker>
                </v-col>
                <v-col>
                  <dp-date-picker
                    type="date"
                    label="取込日To"
                    v-model="searchModel.importDateTo"
                    dense
                    :rules="[rules.importDateToRule]"
                  ></dp-date-picker>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col>
                  <v-text-field
                    v-model="searchModel.companyName"
                    :rules="[rules.maxLength(150)]"
                    label="法人名"
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col>
                  <v-text-field
                    v-model="searchModel.storeName"
                    :rules="[rules.maxLength(150)]"
                    label="取引先名"
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col>
                  <v-text-field
                    label="一括取込番号"
                    v-model="searchModel.importNumber"
                    :rules="[rules.maxLength(12), rules.isNumber]"
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>
            </search-conditions>
          </v-form>
          <tooltip-icon-button icon="mdi-refresh" @click="onBtnSearch">リフレッシュ</tooltip-icon-button>
          <v-divider vertical></v-divider>
          <tooltip-icon-menu-button
            v-if="isCafereoUser && allowedAction(['C040702', 'C040703'])"
            text="送り状データ"
            icon="mdi-text-box-check"
          >
            <v-list dense>
              <v-list-item
                v-if="allowedAction(['C040702'])"
                @click="onDeliveryInvoiceImportClick"
                :disabled="!enabledDeliveryInvoiceImport"
                dense
              >
                <v-list-item-avatar class="ma-0"><v-icon small>mdi-database-import</v-icon></v-list-item-avatar>
                <v-list-item-title>送り状データ取込</v-list-item-title>
              </v-list-item>
              <v-list-item
                v-if="allowedAction(['C040703'])"
                @click="onDeliveryInvoiceRemoveClick"
                :disabled="!enabledDeliveryInvoiceRemove"
                dense
              >
                <v-list-item-avatar class="ma-0"><v-icon small>mdi-trash-can-outline</v-icon></v-list-item-avatar>
                <v-list-item-title>送り状データ削除</v-list-item-title>
              </v-list-item>
            </v-list>
          </tooltip-icon-menu-button>
          <v-dialog
            v-model="deliveryInvoiceImportDialog.show"
            :max-width="deliveryInvoiceImportDialog.width"
            persistent
            scrollable
          >
            <delivery-invoice-import
              v-if="deliveryInvoiceImportDialog.show"
              @complete="onDeliveryInvoiceImportComplete"
              @expand="deliveryInvoiceImportDialog.width = $event ? '100%' : '800px'"
            ></delivery-invoice-import>
          </v-dialog>
          <v-divider vertical></v-divider>
          <tooltip-icon-button
            icon="mdi-database-import"
            @click="onImportClick"
            :disabled="!enabledImport"
            v-if="allowedAction(['C040704'])"
            >実績取込</tooltip-icon-button
          >
          <v-dialog v-model="importDialog.show" :max-width="importDialog.width" persistent scrollable>
            <shipment-achievement-import
              v-if="importDialog.show"
              @complete="onImportComplete"
              @expand="importDialog.width = $event ? '100%' : '800px'"
            ></shipment-achievement-import>
          </v-dialog>
          <tooltip-icon-button
            v-if="allowedAction(['C040708'])"
            icon="mdi-database-edit"
            @click="onCafereoInspectedClick"
            :disabled="!enabledCafereoInspected"
            >CR検品</tooltip-icon-button
          ><v-dialog
            v-model="cafereoInspectedDialog.show"
            :max-width="cafereoInspectedDialog.width"
            persistent
            scrollable
          >
            <cafereo-Inspected
              :inputModel="selectionRows"
              v-if="cafereoInspectedDialog.show"
              @cancel="onCafereoInspectedDialogClose"
              @updated="onCafereoInspectedDialogClose"
            ></cafereo-Inspected>
          </v-dialog>
          <v-divider vertical></v-divider>
          <tooltip-icon-button
            v-if="isCafereoUser && allowedAction(['C040705'])"
            :disabled="!enabledRemove"
            icon="mdi-trash-can-outline"
            @click="onRemoveClick"
            >削除</tooltip-icon-button
          >
          <tooltip-icon-button
            v-if="isCafereoUser && allowedAction(['C040706'])"
            :disabled="!enabledDifference"
            icon="mdi-database-check"
            @click="onDifferenceClick"
            >差分対処</tooltip-icon-button
          >
          <v-dialog v-model="differenceDialog" max-width="500px" persistent scrollable>
            <shipment-achievment-difference-select
              :inputModel="selectedRow"
              v-if="differenceDialog"
              @cancel="differenceDialog = false"
              @updated="onDifferenceSelect"
            ></shipment-achievment-difference-select>
          </v-dialog>
          <tooltip-icon-button
            v-if="isCafereoUser && allowedAction(['C040707'])"
            :disabled="!enabledGoodsReceiptNumber"
            icon="mdi-receipt"
            @click="onGoodsReceiptNumberClick"
            >物品受領番号登録</tooltip-icon-button
          >
          <v-dialog v-model="goodsReceiptNumberDialog" max-width="1000px" persistent scrollable>
            <goods-receipt-number-entry
              :inputModel="selectionRows"
              v-if="goodsReceiptNumberDialog"
              @cancel="onGoodsReceiptNumberClose"
              @updated="onGoodsReceiptNumberClose"
            ></goods-receipt-number-entry>
          </v-dialog>
          <!-- <tooltip-icon-button icon="mdi-book-arrow-down" @click="onDeliveryReportPrint"
            >納品書出力</tooltip-icon-button
          > -->
          <tooltip-icon-button
            v-if="allowedAction(['C040710'])"
            :disabled="enabledUpdateSlipNo"
            icon="mdi-database-edit"
            @click="onUpdateSlipNoClick"
            >伝票番号更新</tooltip-icon-button
          >
          <v-dialog v-model="updateSlipNoDialog" max-width="1000px" persistent scrollable>
            <slip-no-update
              :inputModel="selectionRows"
              v-if="updateSlipNoDialog"
              @cancel="onUpdateSlipNoDialogClose"
              @updated="onUpdateSlipNoDialogClose"
            ></slip-no-update>
          </v-dialog>
          <tooltip-icon-button icon="mdi-download" @click="onExportClick">CSVダウンロード</tooltip-icon-button>
          <tooltip-icon-button icon="mdi-filter-off" @click="clearFilters()">フィルター解除</tooltip-icon-button>
          <v-divider vertical></v-divider>
          <tooltip-icon-toggle-button icon="mdi-information-outline" v-model="shownInfo"
            >詳細表示</tooltip-icon-toggle-button
          >
        </v-app-bar>
      </v-col>
    </v-row>
    <v-row style="height: 95%">
      <v-col :style="gridStyle" :cols="shownInfo ? 9 : 12" v-show="!shownInfo || !infoMaximum">
        <v-row>
          <v-col cols="3">
            <v-card v-if="isCafereoUser" elevation="1" class="mt-2">
              <v-card-text class="font-weight-bold ml-4 mb-4">
                出荷数合計：{{ this.shipQuantityTotal | comma }}
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="3">
            <v-card v-if="isCafereoUser" elevation="1" class="mt-2">
              <v-card-text class="font-weight-bold ml-4 mb-4">
                販売金額合計：{{ this.purchaseTotalPriceTotal | comma }}
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="3">
            <v-card v-if="isCafereoUser" elevation="1" class="mt-2">
              <v-card-text class="font-weight-bold ml-4 mb-4">
                粗利金額合計：{{ this.grossProfitTotalPriceTotal | comma }}
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="3">
            <v-card v-if="isCafereoUser" elevation="1" class="mt-2">
              <v-card-text class="font-weight-bold ml-4 mb-4">
                粗利率：{{ this.grossProfitMarginTotal.toFixed(1) }}%
              </v-card-text>
            </v-card>
          </v-col>
          <v-spacer></v-spacer
        ></v-row>
        <ag-grid-vue
          :gridOptions="gridOptions"
          :rowData="dataRecords"
          class="ag-theme-alpine"
          style="height: 100%"
          @selection-changed="onSelectionChanged"
        ></ag-grid-vue>
      </v-col>
      <v-col :cols="infoMaximum ? 12 : 3" v-if="shownInfo" style="flex-basis: auto; display: flex">
        <v-divider vertical></v-divider>
        <shipment-achievement-infos
          v-on:infoMaximum-event="infoMaximum = !infoMaximum"
          :details="selectedDetails"
          :shippingInstructionId="selectedRow != null ? selectedRow.shippingInstructionId : null"
        ></shipment-achievement-infos>
      </v-col>
    </v-row>
    <error-grid-dialog
      ref="errorGrid"
      width="80%"
      height="80%"
      icon="mdi-calendar-check"
      title="承認"
      btnSubmit="登録"
      :columns="errorColmuns"
    ></error-grid-dialog>
  </v-container>
</template>

<style>
@import "../../../node_modules/ag-grid-community/dist/styles/ag-grid.css";
@import "../../../node_modules/ag-grid-community/dist/styles/ag-theme-alpine.css";
</style>

<script>
import moment from "moment";
import { AgGridVue } from "ag-grid-vue";
import { AG_GRID_LOCALE_JA } from "../../models/ag-grid/locales";
import {
  NumericColumn,
  PercentColumn,
  DateColumn,
  FullDateColumn,
  CheckmarkColumn,
  IncludeFilter,
} from "../../models/ag-grid/columnTypes";
import SearchConditions from "../../components/common/SearchConditions.vue";
import TooltipIconButton from "../../components/common/TooltipIconButton.vue";
import TooltipIconMenuButton from "../../components/common/TooltipIconMenuButton.vue";
import TooltipIconToggleButton from "../../components/common/TooltipIconToggleButton.vue";
import ShipmentAchievementImport from "../../components/stock/ShipmentAchievementImport.vue";
import ShipmentAchievmentDifferenceSelect from "../../components/stock/ShipmentAchievmentDifferenceSelect.vue";
import DeliveryInvoiceImport from "../../components/stock/DeliveryInvoiceImport.vue";
import { comma } from "../../filter/NumberFilter";
import Storages from "../../consts/Storages";
import { statuses as ApiStatus } from "../../libs/api-client";
import ErrorGridDialog from "../../components/common/ErrorGridDialog.vue";
import ShipmentAchievementInfos from "./../../components/stock/ShipmentAchievementInfos.vue";
import { getDisplayDetails2, typeFormat } from "../../models/ag-grid/helpers";
import Validation from "../../libs/validation";
import GoodsReceiptNumberEntry from "../../components/stock/GoodsReceiptNumberEntry.vue";
import SlipNoUpdate from "../../components/stock/SlipNoUpdate.vue";
import CafereoInspected from "../../components/stock/CafereoInspected.vue";
import { corpList as CorpList } from "../../consts/CorpList";
import { BooleanFilter, SelectionFilter } from "../../components/ag-grid/filters";

export default {
  name: "ShipmentAchievementSearch",
  components: {
    AgGridVue,
    SearchConditions,
    TooltipIconButton,
    TooltipIconMenuButton,
    TooltipIconToggleButton,
    ShipmentAchievementImport,
    ShipmentAchievmentDifferenceSelect,
    DeliveryInvoiceImport,
    ErrorGridDialog,
    ShipmentAchievementInfos,
    GoodsReceiptNumberEntry,
    SlipNoUpdate,
    CafereoInspected,
  },
  data() {
    return {
      gridStyle: { height: "95%" },
      shownInfo: false,
      infoMaximum: false,
      activeTab: null,
      selectedPageSize: 10,
      gridOptions: {
        columnTypes: {
          dpNumericColumn: NumericColumn,
          dpPercentColumn: PercentColumn,
          dpDateColumn: DateColumn,
          dpFullDateColumn: FullDateColumn,
          dpCheckmarkColumn: CheckmarkColumn,
        },
        defaultColDef: {
          resizable: true,
          sortable: true,
          filter: "agTextColumnFilter",
          filterParams: {
            newRowsAction: "keep",
          },
        },
        frameworkComponents: {
          dpBooleanFilter: BooleanFilter,
          dpSelectionFilter: SelectionFilter,
        },
        columnDefs: [
          {
            headerCheckboxSelection: true,
            headerCheckboxSelectionFilteredOnly: true,
            checkboxSelection: true,
            filter: false,
            resizable: false,
            sortable: false,
            pinned: "left",
          },
          { headerName: "出荷日", field: "shipDate", type: "dpDateColumn", pinned: "left" },
          { headerName: "取込日", field: "importDate", type: "dpDateColumn", pinned: "left" },
          { headerName: "伝票日付", field: "slipDate", type: "dpDateColumn", pinned: "left" },
          { headerName: "伝票番号", field: "slipNo", pinned: "left" },
          { headerName: "社店CD", field: "companyStoreCode" },
          { headerName: "法人名", field: "companyName" },
          { headerName: "取引先名", field: "storeName", filterParams: IncludeFilter },
          { headerName: "JANCODE", field: "janCode" },
          {
            headerName: "仕入先略称",
            field: "supplierName",
            // valueGetter: (params) => params.data.makerName.replace("株式会社", ""),
          },
          { headerName: "タイトル", field: "title", filterParams: IncludeFilter },
          { headerName: "商品名", field: "productName", filterParams: IncludeFilter },
          { headerName: "上代", field: "retailPrice", type: "dpNumericColumn" },
          {
            headerName: "卸掛率",
            field: "wholesaleRate",
            type: "dpPercentColumn",
          },
          { headerName: "卸単価", field: "wholesaleUnitPrice", type: "dpNumericColumn" },
          { headerName: "出荷数", field: "shipQuantity", type: "dpNumericColumn" },
          { headerName: "単価合計金額", field: "purchaseTotalPrice", type: "dpNumericColumn" },
          {
            headerName: "粗利率",
            field: "grossProfitMargin",
            type: "dpPercentColumn",
          },
          { headerName: "粗利金額", field: "grossProfitPrice", type: "dpNumericColumn" },
          { headerName: "粗利合計", field: "grossProfitTotalPrice", type: "dpNumericColumn" },
          { headerName: "営業担当者", field: "salesStaff" },
          { headerName: "アシスタント名", field: "salesAssistant" },
          { headerName: "伝票備考", field: "slipRemarks" },
          { headerName: "社内備考", field: "cafereoRemarks" },
          { headerName: "発注日", field: "orderDate", type: "dpDateColumn", filter: "agDateColumnFilter" },
          { headerName: "配送CD", field: "shipCode" },
          { headerName: "届先部署名", field: "shipDepartmentName" },
          { headerName: "届先名", field: "shipName" },
          { headerName: "発生元番号", field: "originalNumber" },
          { headerName: "アニメイト用伝票日付", field: "animateSlipDate", type: "dpDateColumn" },
          { headerName: "在庫保管場所", field: "storageName" },
          { headerName: "物品受領番号", field: "goodsReceiptNumber" },
          { headerName: "一括取込番号", field: "importNumber" },
          { headerName: "届先住所", field: "shipAddress" },
          { headerName: "送状発行日", field: "deliveryInvoiceIssuanceDate", type: "dpDateColumn" },
          { headerName: "送状番号", field: "deliveryInvoiceNumber" },
          { headerName: "移動先入荷フラグ", field: "cafereoInspected", type: "dpCheckmarkColumn" },
          { headerName: "登録日時", field: "createDatetime", type: "dpDateColumn" },
          { headerName: "登録者", field: "createUser" },
          { headerName: "更新日時", field: "updateDatetime", hide: true },
        ],
        rowSelection: "multiple",
        suppressCellSelection: true,
        pagination: true,
        paginationPageSize: this.globalPageSize,
        enableCellTextSelection: true,
        localeText: AG_GRID_LOCALE_JA,
        getRowNodeId: (data) => data.shippingInstructionId,
      },
      dataRecords: [],
      selectionRows: [],
      searchModel: {
        fetchLimitOverFlg: false,
        shipDateFrom: null,
        shipDateTo: null,
        janCode: null,
        importDateFrom: moment().format("YYYY-MM-DD"),
        importDateTo: moment().format("YYYY-MM-DD"),
        importNumber: null,
        storeName: null,
        companyName: null,
      },
      importDialog: {
        show: false,
        width: "800px",
      },
      differenceDialog: false,
      goodsReceiptNumberDialog: false,
      deliveryInvoiceImportDialog: {
        show: false,
        width: "800px",
      },
      updateSlipNoDialog: false,
      cafereoInspectedDialog: {
        show: false,
        width: "100%",
      },
      shipQuantityTotal: 0,
      purchaseTotalPriceTotal: 0,
      grossProfitTotalPriceTotal: 0,
      grossProfitMarginTotal: 0,
      errorColmuns: [
        { headerName: "出荷ID", field: "shippingInstructionId" },
        {
          headerName: "エラー内容",
          field: "errorMessage",
          wrapText: true,
          autoHeight: true,
          cellRenderer: function (param) {
            return param.data.errorMessage.join("<br>");
          },
        },
      ],
      rules: {
        maxLength: Validation.maxLength,
        isNumber: Validation.isNumber,
        isJancodeSearchList: Validation.isJancodeSearchList,
        shipDateFromRule: (value) => this.shipDateFromRules(value),
        shipDateToRule: (value) => this.shipDateToRules(value),
        importDateFromRule: (value) => this.importDateFromRules(value),
        importDateToRule: (value) => this.importDateToRules(value),
      },
      validSearchForm: null,
      detailColumnDefs: [
        { headerName: "出荷日", field: "shipDate", type: "dpFullDateColumn", pinned: "left" },
        { headerName: "伝票日付", field: "slipDate", type: "dpFullDateColumn", pinned: "left" },
        { headerName: "伝票番号", field: "slipNo", pinned: "left" },
        { headerName: "社店CD", field: "companyStoreCode" },
        { headerName: "法人名", field: "companyName" },
        { headerName: "取引先名", field: "storeName" },
        { headerName: "JANCODE", field: "janCode" },
        {
          headerName: "仕入先略称",
          field: "supplierName",
        },
        { headerName: "タイトル", field: "title" },
        { headerName: "商品名", field: "productName" },
        { headerName: "上代", field: "retailPrice", type: "dpNumericColumn" },
        { headerName: "仕入掛率", field: "wholesaleRate", type: "dpPercentColumn" },
        { headerName: "卸単価", field: "wholesaleUnitPrice", type: "dpNumericColumn" },
        { headerName: "出荷数", field: "shipQuantity", type: "dpNumericColumn" },
        { headerName: "単価合計金額", field: "purchaseTotalPrice", type: "dpNumericColumn" },
        { headerName: "粗利率", field: "grossProfitMargin", type: "dpPercentColumn" },
        { headerName: "粗利金額", field: "grossProfitPrice", type: "dpNumericColumn" },
        { headerName: "粗利合計", field: "grossProfitTotalPrice", type: "dpNumericColumn" },
        { headerName: "営業担当者", field: "salesStaff" },
        { headerName: "アシスタント名", field: "salesAssistant" },
        { headerName: "伝票備考", field: "slipRemarks" },
        { headerName: "社内備考", field: "cafereoRemarks" },
        { headerName: "発注日", field: "orderDate", type: "dpDateColumn", filter: "agDateColumnFilter" },
        { headerName: "配送CD", field: "shipCode" },
        { headerName: "届先部署名", field: "shipDepartmentName" },
        { headerName: "届先名", field: "shipName" },
        { headerName: "発生元番号", field: "originalNumber" },
        { headerName: "アニメイト用伝票日付", field: "animateSlipDate", type: "dpFullDateColumn" },
        { headerName: "在庫保管場所", field: "storageName" },
        { headerName: "物品受領番号", field: "goodsReceiptNumber" },
        { headerName: "一括取込番号", field: "importNumber" },
        { headerName: "届先住所", field: "shipAddress" },
        { headerName: "送状発行日", field: "deliveryInvoiceIssuanceDate", type: "dpFullDateColumn" },
        { headerName: "送状番号", field: "deliveryInvoiceNumber" },
        { headerName: "移動先入荷フラグ", field: "cafereoInspected", type: "dpCheckmarkColumn" },
        { headerName: "登録日時", field: "createDatetime", type: "dpFullDateColumn" },
        { headerName: "登録者", field: "createUser" },
      ],
      sofmapCorporateCode: CorpList.SOFMAP,
    };
  },
  filters: { comma },
  watch: {
    globalPageSize(size) {
      this.gridOptions.api.paginationSetPageSize(size);
      this.handleResize();
    },
    selectedPageSize(size) {
      this.gridOptions.api.paginationSetPageSize(size);
    },
    gridHeightSize(value) {
      this.gridStyle.height = value - 96.5 + "px";
    },
  },
  mounted() {
    this.onSearchClick();
    this.handleResize();
    this.gridStyle.height = this.gridHeightSize - 96.5 + "px";
    window.addEventListener("resize", this.handleResize);
  },
  computed: {
    selectedRow() {
      return this.selectionRows.length === 1 ? this.selectionRows[0] : null;
    },
    selectedDetails() {
      if (!this.selectedRow) {
        return [];
      }
      return getDisplayDetails2(this.selectedRow.shippingInstructionId, this.detailColumnDefs, this.gridOptions.api);
    },
    enabledDeliveryInvoiceImport() {
      return this.allowedAction(["C040702"]);
    },
    enabledDeliveryInvoiceRemove() {
      return this.selectedRow && this.allowedAction(["C040703"]);
    },
    enabledImport() {
      return this.allowedAction(["C040704"]);
    },
    enabledCafereoInspected() {
      if (!this.allowedAction(["C040708"])) return false;
      if (this.selectionRows.length === 0) return false;
      return this.selectionRows.every((row) => !row.cafereoInspected && row.movingWarehouseCode == Storages.CAFEREO);
    },
    enabledRemove() {
      return this.selectionRows.length !== 0 && this.allowedAction(["C040705"]);
    },
    enabledDifference() {
      return this.selectedRow && this.allowedAction(["C040706"]);
    },
    enabledGoodsReceiptNumber() {
      if (!this.allowedAction(["C040707"])) return false;
      if (this.selectionRows.length === 0) return false;
      return this.selectionRows.every((row) => row.corporationCd === this.sofmapCorporateCode);
    },
    enabledUpdateSlipNo() {
      if (!this.allowedAction(["C040710"])) return true;
      return this.selectionRows.length === 0;
    },
    enabledCsvExport() {
      return this.allowedAction(["C040709"]);
    },
  },
  methods: {
    clearFilters() {
      this.gridOptions.api.setFilterModel(null);
      this.gridOptions.columnApi.applyColumnState({
        defaultState: { sort: null },
      });
    },

    async onSearchClick() {
      try {
        this.loadingOn();
        this.selectionRows = [];
        const params = {
          fetchLimitOverFlg: this.searchModel.fetchLimitOverFlg,
          shipDateFrom: this.searchModel.shipDateFrom,
          shipDateTo: this.searchModel.shipDateTo,
          janCode: this.searchModel.janCode
            ? this.searchModel.janCode
                .replaceAll(/\n+/g, " ")
                .split(" ")
                .filter((n) => n)
            : null,
          importDateFrom: this.searchModel.importDateFrom,
          importDateTo: this.searchModel.importDateTo,
          importNumber: this.searchModel.importNumber,
          storeName: this.searchModel.storeName,
          companyName: this.searchModel.companyName,
        };
        const response = await this.$store.dispatch("shipmentAchievement/search", params);
        if (ApiStatus.isSystemError(response.data?.header)) {
          return this.redirectError();
        }
        var result = response.data.contents;
        if (result.over) {
          this.$dialog.warning({
            title: "出荷一覧",
            text: `検索上限数を超えました。結果は${result.limit}件まで表示されます。`,
            actions: ["OK"],
          });
        }
        if (Object.keys(result.shipmentAchievements).length === 0) {
          this.$dialog.warning({
            title: "出荷一覧",
            text: `検索結果は0件です。`,
            actions: ["OK"],
          });
        }
        this.gridOptions.api.setRowData(result.shipmentAchievements);
        const allColumnIds = this.gridOptions.columnApi.getAllColumns().map((column) => column.colId);
        this.gridOptions.columnApi.autoSizeColumns(allColumnIds);
        this.calcTotalItem();
      } catch (error) {
        console.error("ShipmentAchievementSearch::onSearchClick", error);
        this.apiRequestError(error);
      } finally {
        this.loadingOff();
      }
    },
    onExportClick() {
      let allColumnIds = [];
      this.gridOptions.columnApi.getAllColumns().forEach(function (column, idx) {
        if (idx > 0 && !column.colDef.hide) allColumnIds.push(column.colId);
      });
      this.gridOptions.api.exportDataAsCsv({
        allColumns: true,
        onlySelected: this.selectionRows.length > 0,
        fileName: `出荷実績一覧`,
        columnKeys: allColumnIds,
        processCellCallback: (params) => typeFormat(params.column.colDef?.type, params.value),
      });
    },
    async onRemoveClick() {
      let messageText = `選択された実績を削除します`;
      const ok = await this.$dialog.confirm({ title: "出荷実績", text: messageText });
      if (ok) {
        try {
          this.loadingOn();
          let updateModels = [];
          this.selectionRows.forEach((row) => {
            updateModels.push({
              shippingInstructionId: row.shippingInstructionId,
              updateDatetime: row.updateDatetime,
            });
          });
          const response = await this.$store.dispatch("shipmentAchievement/delete", {
            shipmentAchievements: updateModels,
          });
          let error = response.data?.header;
          // 一覧更新レコード
          let updateRows = [];
          let errorRows = [];
          switch (error.resultCode) {
            case ApiStatus.consts.SUCCESS:
            case ApiStatus.consts.BUSINESS_ERROR:
            case ApiStatus.consts.ALREADY_CHANGED:
              // エラーメッセージ格納
              if (error.messages) {
                Object.keys(error.messages).forEach((key) => {
                  errorRows.push({
                    shippingInstructionId: key,
                    errorMessage: error.messages[key],
                  });
                });
              }
              // 一覧部の更新分のレコード変更
              if (response.data.contents) {
                this.selectionRows.forEach((row) => {
                  response.data.contents.shipmentAchievements.forEach((shipment) => {
                    let isError = errorRows.some((r) => r.shippingInstructionId === row.shippingInstructionId);
                    if (shipment.shippingInstructionId == row.shippingInstructionId && !isError) {
                      row.shipDate = shipment.shipDate;
                      row.slipDate = shipment.slipDate;
                      row.slipNo = shipment.slipNo;
                      row.companyStoreCode = shipment.companyStoreCode;
                      row.companyName = shipment.companyName;
                      row.storeName = shipment.storeName;
                      row.janCode = shipment.janCode;
                      row.supplierName = shipment.supplierName;
                      row.title = shipment.title;
                      row.productName = shipment.productName;
                      row.retailPrice = shipment.retailPrice;
                      row.wholesaleRate = shipment.wholesaleRate;
                      row.wholesaleUnitPrice = shipment.wholesaleUnitPrice;
                      row.shipQuantity = shipment.shipQuantity;
                      row.purchaseTotalPrice = shipment.purchaseTotalPrice;
                      row.grossProfitMargin = shipment.grossProfitMargin;
                      row.grossProfitPrice = shipment.grossProfitPrice;
                      row.grossProfitTotalPrice = shipment.grossProfitTotalPrice;
                      row.salesStaff = shipment.salesStaff;
                      row.shipCode = shipment.shipCode;
                      row.shipDepartmentName = shipment.shipDepartmentName;
                      row.shipName = shipment.shipName;
                      row.originalNumber = shipment.originalNumber;
                      row.animateSlipDate = shipment.animateSlipDate;
                      row.storageName = shipment.storageName;
                      row.goodsReceiptNumber = shipment.goodsReceiptNumber;
                      row.importNumber = shipment.importNumber;
                      row.shipAddress = shipment.shipAddress;
                      row.deliveryInvoiceIssuanceDate = shipment.deliveryInvoiceIssuanceDate;
                      row.deliveryInvoiceNumber = shipment.deliveryInvoiceNumber;
                      row.cafereoInspected = shipment.cafereoInspected;
                      row.createDatetime = shipment.createDatetime;
                      row.createUser = shipment.createUser;
                      row.updateDatetime = shipment.updateDatetime;
                      updateRows.push(row);
                    }
                  });
                });
              }
              // 一覧部のレコードを更新
              if (updateRows.length > 0) this.gridOptions.api.applyTransaction({ update: updateRows });
              // エラー表示
              if (errorRows.length > 0) {
                this.$refs.errorGrid.open({ records: errorRows });
                this.$dialog.notify.error(`出荷実績削除処理に失敗したデータが存在します。ご確認ください。`, {
                  timeout: 2300,
                });
              } else {
                this.$dialog.notify.info(`出荷実績情報を削除しました`, { timeout: 2300 });
              }
              break;
            default:
              this.redirectError();
              break;
          }
        } catch (error) {
          console.error("ShipmentAchievementSearch::onRemoveClick", error);
          this.apiRequestError(error);
        } finally {
          this.loadingOff();
        }
      }
    },
    onImportClick() {
      this.importDialog.show = true;
    },
    onImportComplete(canceled) {
      this.importDialog.show = false;
      console.log("ShipmentAchievementSearch::onImportComplete", "canceled", canceled);
      if (!canceled) {
        this.onSearchClick();
      }
    },
    onSelectionChanged() {
      this.selectionRows = this.gridOptions.api.getSelectedRows();
    },
    onDifferenceClick() {
      this.differenceDialog = true;
    },
    onDifferenceSelect(shipmentAchievementRecord) {
      this.selectedRow.differenceCope = shipmentAchievementRecord.differenceCope;
      this.selectedRow.updateDatetime = shipmentAchievementRecord.updateDatetime;
      this.gridOptions.api.applyTransaction({ update: [this.selectedRow] });
      this.differenceDialog = false;
    },
    onGoodsReceiptNumberClick() {
      this.goodsReceiptNumberDialog = true;
    },
    onGoodsReceiptNumberClose(updateRecords) {
      this.selectionRows.forEach((row) => {
        updateRecords.forEach((record) => {
          if (record.shippingInstructionId == row.shippingInstructionId) {
            row.goodsReceiptNumber = record.goodsReceiptNumber;
            row.updateDatetime = record.updateDatetime;
          }
        });
      });
      this.gridOptions.api.applyTransaction({ update: this.selectionRows });
      this.goodsReceiptNumberDialog = false;
    },
    onDeliveryInvoiceImportClick() {
      this.deliveryInvoiceImportDialog.show = true;
    },
    onDeliveryInvoiceImportComplete(canceled) {
      this.deliveryInvoiceImportDialog.show = false;
      console.log("ShipmentAchievementSearch::onDeliveryInvoiceImportComplete", "canceled", canceled);
      if (!canceled) {
        this.onSearchClick();
      }
    },
    async onDeliveryInvoiceRemoveClick() {
      let messageText = `選択されたデータの送り状データを削除します`;
      const ok = await this.$dialog.confirm({ title: "出荷実績", text: messageText });
      if (ok) {
        try {
          this.loadingOn();
          const param = {
            shipmentAchievementId: this.selectedRow.shippingInstructionId,
            updateDatetime: this.selectedRow.updateDatetime,
          };
          const response = await this.$store.dispatch("shipmentAchievement/shippinglabelDelete", param);
          console.log(response);
          let error = response.data?.header;
          let errorRows = [];
          switch (error.resultCode) {
            case ApiStatus.consts.SUCCESS:
              var shipmentAchievement = response.data.contents.shipmentAchievement;
              this.selectedRow.deliveryInvoiceIssuanceDate = "";
              this.selectedRow.deliveryInvoiceNumber = "";
              this.selectedRow.updateDatetime = shipmentAchievement.updateDatetime;
              this.selectedRow.updateUser = shipmentAchievement.updateUser;
              this.gridOptions.api.applyTransaction({ update: [this.selectedRow] });
              this.$dialog.notify.info(`送り状データを削除しました`, { timeout: 2300 });
              break;
            case ApiStatus.consts.BUSINESS_ERROR:
            case ApiStatus.consts.ALREADY_CHANGED:
              // エラーメッセージ格納
              if (error.messages) {
                Object.keys(error.messages).forEach((key) => {
                  errorRows.push({
                    shippingInstructionId: key,
                    errorMessage: error.messages[key],
                  });
                });
              }
              // エラー表示
              if (errorRows.length > 0) {
                this.$refs.errorGrid.open({ records: errorRows });
                this.$dialog.notify.error(`送り状データの削除処理に失敗したデータが存在します。ご確認ください。`, {
                  timeout: 2300,
                });
              }
              break;
            default:
              this.redirectError();
              break;
          }
        } catch (error) {
          console.error("ShipmentAchievementSearch::onDecisionCancelClick", error);
          this.apiRequestError(error);
        } finally {
          this.loadingOff();
        }
      }
    },
    // onDeliveryReportPrint() {
    //   window.open("/samples/納品書.xlsx", "_blank");
    // },
    calcTotalItem() {
      this.shipQuantityTotal = 0;
      this.purchaseTotalPriceTotal = 0;
      this.grossProfitTotalPriceTotal = 0;
      this.grossProfitMarginTotal = 0;
      let rowData = [];
      this.gridOptions.api.forEachNode((node) => rowData.push(node.data));
      for (let row of rowData) {
        this.shipQuantityTotal += row.shipQuantity ? row.shipQuantity : 0;
        this.purchaseTotalPriceTotal += row.purchaseTotalPrice ? row.purchaseTotalPrice : 0;
        this.grossProfitTotalPriceTotal += row.grossProfitTotalPrice ? row.grossProfitTotalPrice : 0;
      }
      if (this.grossProfitTotalPriceTotal != 0 && this.purchaseTotalPriceTotal != 0) {
        this.grossProfitMarginTotal =
          Math.round((this.grossProfitTotalPriceTotal / this.purchaseTotalPriceTotal) * 1000) / 10;
      }
    },
    onCafereoInspectedClick() {
      this.cafereoInspectedDialog.show = true;
    },
    onCafereoInspectedDialogClose(updateRecode) {
      if (updateRecode) {
        let updateRows = [];
        this.selectionRows.forEach((row) => {
          updateRecode.forEach((shipment) => {
            if (shipment.shippingInstructionId == row.shippingInstructionId) {
              row.cafereoInspected = shipment.cafereoInspected;
              row.updateDatetime = shipment.updateDatetime;
              updateRows.push(row);
            }
          });
        });
        // 一覧部のレコードを更新
        if (updateRows.length > 0) this.gridOptions.api.applyTransaction({ update: updateRows });
      }
      this.cafereoInspectedDialog.show = false;
    },
    onUpdateSlipNoClick() {
      this.updateSlipNoDialog = true;
    },
    onUpdateSlipNoDialogClose(updateSlipNoRecode) {
      if (updateSlipNoRecode) {
        let updateRows = [];
        this.selectionRows.forEach((row) => {
          updateSlipNoRecode.forEach((shipment) => {
            if (shipment.shippingInstructionId == row.shippingInstructionId) {
              row.slipNo = shipment.slipNo;
              row.updateDatetime = shipment.updateDatetime;
              updateRows.push(row);
            }
          });
        });
        // 一覧部のレコードを更新
        if (updateRows.length > 0) this.gridOptions.api.applyTransaction({ update: updateRows });
      }
      this.updateSlipNoDialog = false;
    },
    onBtnSearch() {
      const isValid = this.$refs.searchForm.validate();
      if (!isValid) {
        this.$dialog.notify.error(`入力エラーがあります`, { timeout: 2300 });
        return;
      }
      this.$refs.searchForm.resetValidation();
      this.onSearchClick();
    },
    shipDateFromRules(value) {
      if (value == null || this.searchModel.shipDateTo == null) return true;
      if (moment(new Date(value)).isAfter(this.searchModel.shipDateTo)) return "出庫日Toより前の日付を指定してください";
      return true;
    },
    shipDateToRules(value) {
      if (value == null || this.searchModel.shipDateFrom == null) return true;
      if (moment(new Date(value)).isBefore(this.searchModel.shipDateFrom))
        return "出庫日Fromより後の日付を指定してください";
      return true;
    },
    importDateFromRules(value) {
      if (value == null || this.searchModel.importDateTo == null) return true;
      if (moment(new Date(value)).isAfter(this.searchModel.importDateTo))
        return "取込日Toより前の日付を指定してください";
      return true;
    },
    importDateToRules(value) {
      if (value == null || this.searchModel.importDateFrom == null) return true;
      if (moment(new Date(value)).isBefore(this.searchModel.importDateFrom))
        return "取込日Fromより後の日付を指定してください";
      return true;
    },
  },
};
</script>
