<template>
  <v-card>
    <v-card-title>
      <span class="headline"><v-icon>mdi-database-edit</v-icon>在庫移動一括編集</span>
    </v-card-title>
    <v-divider></v-divider>
    <v-card-text>
      <v-form ref="StockMoveBulkEditForm" lazy-validation>
        <v-container>
          <v-row v-for="(source, index) in settingSources" :key="index" dense>
            <v-col cols="3">
              <v-select
                :items="constant.StockMoveBulkEditColumnsValues"
                label="設定項目"
                v-model="source.column"
                :rules="[rules.required, rules.validSelectColumn]"
                @change="onSettingColSelected(index)"
                dense
                filled
                attach
              ></v-select>
            </v-col>
            <v-col cols="5">
              <v-switch
                v-if="source.column === constant.StockMoveBulkEditColumns.IS_READY"
                v-model="source.value"
                inset
                dense
              ></v-switch>
              <dp-date-picker
                v-if="source.column === constant.StockMoveBulkEditColumns.MOVING_PLAN_DATE"
                label="設定値"
                v-model="source.value"
                type="date"
                :rules="[rules.required, rules.isShipDateRule]"
                filled
                dense
              ></dp-date-picker>
              <dp-date-picker
                v-if="source.column === constant.StockMoveBulkEditColumns.SPECIFIED_DELIVERY_DATE"
                label="設定値"
                v-model="source.value"
                type="date"
                filled
                dense
              ></dp-date-picker>
              <v-text-field
                v-if="source.column === constant.StockMoveBulkEditColumns.MOVING_QUANTITY"
                label="設定値"
                v-model="source.value"
                :rules="[rules.required, rules.isNumber, rules.maxLength(8)]"
                filled
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="1"
              ><v-btn v-if="index != 0" x-small fab dense @click="onDelSettingSource(index)">
                <v-icon dark> mdi-minus </v-icon></v-btn
              ></v-col
            >
          </v-row>
          <v-row dense>
            <v-col cols="12">
              <v-btn x-small fab dense @click="onAddSettingSource">
                <v-icon dark> mdi-plus </v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <v-divider class="mt-3 pt-3"></v-divider>
          <v-row dense>
            <v-col cols="12" sm="12">
              <v-spacer></v-spacer>
              <tooltip-icon-button :disabled="!selectedRows" icon="mdi-arrow-down-bold-outline" @click="onBtnSetUpdate"
                >在庫移動反映</tooltip-icon-button
              >
            </v-col>
            <v-col cols="12" sm="12" style="height: 450px">
              <ag-grid-vue
                class="ag-theme-alpine"
                :defaultColDef="defaultColDef"
                :columnDefs="columnDefs"
                :alwaysShowHorizontalScroll="true"
                :suppressColumnVirtualisation="true"
                :enableCellTextSelection="true"
                :pagination="true"
                paginationPageSize="10"
                :localeText="localeText"
                rowSelection="multiple"
                @grid-ready="onGridReady"
                @selection-changed="onSelectionChanged"
                @column-everything-changed="onColumnEverythingChanged"
                :columnTypes="columnTypes"
                :getRowNodeId="(data) => data.shippingInstructionId"
                :frameworkComponents="frameworkComponents"
                @cell-value-changed="onCellValueChanged"
                @cell-editing-started="onCellEditingStarted"
                style="height: 100%"
              >
              </ag-grid-vue>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions>
      <v-btn color="secondary" @click="onCancelClick">キャンセル</v-btn>
      <v-spacer></v-spacer>
      <v-btn color="primary" @click="onUpdateClick">更新</v-btn>
    </v-card-actions>
    <v-overlay :value="isLoading">
      <v-progress-circular indeterminate color="primary" size="64"></v-progress-circular>
    </v-overlay>
    <error-grid-dialog
      ref="updateErrorGrid"
      width="80%"
      height="80%"
      icon=""
      title="移動一括編集"
      btnSubmit="登録"
      :columns="errorColmuns"
    ></error-grid-dialog>
  </v-card>
</template>

<script>
import { AgGridVue } from "ag-grid-vue";
import { AG_GRID_LOCALE_JA } from "../../models/ag-grid/locales";
import {
  NumericColumn,
  PercentColumn,
  DateColumn,
  FullDateColumn,
  CheckmarkColumn,
  EditableColumn,
  EditableDateColumn,
  EditableCheckBoxColumn,
  EditableCheckmarkColumn,
} from "../../models/ag-grid/columnTypes";
import StockMoveBulkEditColumns from "../../consts/editColumns/StockMoveBulkEditColumns";
import { ShippingInstructionColumns as ShippingInstructionColumnDefs } from "../../consts/columns/StockMoveColumns";
import TooltipIconButton from "../common/TooltipIconButton.vue";
import {
  DateCellEditor,
  CheckboxCellEditor,
  CustomDateCellEditor,
  NumericCellEditor,
} from "../../components/ag-grid/cellEditors";
import ErrorGridDialog from "../../components/common/ErrorGridDialog.vue";
import { statuses as ApiStatus } from "../../libs/api-client";
import Validation from "../../libs/validation";
import moment from "moment";
import {} from "../../components/ag-grid/cellEditors";
import { BooleanFilter, SelectionFilter } from "../../components/ag-grid/filters";
import FtProductConditionType from "../../consts/FtProductConditionTypes";

export default {
  name: "StockMoveBulkEdit",
  props: ["inputModel"],
  components: {
    AgGridVue,
    TooltipIconButton,
    ErrorGridDialog,
    /* eslint-disable vue/no-unused-components */
    CustomDateCellEditor,
    NumericCellEditor,
  },
  data() {
    return {
      updateModel: {},
      defaultModel: {
        movingPlanDate: null,
        specifiedDeliveryDate: null,
        isReady: null,
        movingQuantity: null,
      },
      defaultColDef: null,
      columnDefs: null,
      gridApi: null,
      columnApi: null,
      selectedRows: null,
      domLayout: null,
      localeText: AG_GRID_LOCALE_JA,
      updateList: [],
      settingSources: [],
      constant: {
        StockMoveBulkEditColumnsValues: StockMoveBulkEditColumns.all(),
        StockMoveBulkEditColumns: StockMoveBulkEditColumns,
      },
      columnTypes: {
        dpNumericColumn: NumericColumn,
        dpPercentColumn: PercentColumn,
        dpDateColumn: DateColumn,
        dpFullDateColumn: FullDateColumn,
        dpCheckmarkColumn: CheckmarkColumn,
        dpEditableCheckmarkColumn: EditableCheckmarkColumn,
        dpEditableColumn: EditableColumn,
        dpEditableDateColumn: EditableDateColumn,
        dpEditableCheckBoxColumn: EditableCheckBoxColumn,
      },
      frameworkComponents: {
        dpDateCellEditor: DateCellEditor,
        dpCheckboxCellEditor: CheckboxCellEditor,
        dpBooleanFilter: BooleanFilter,
        dpSelectionFilter: SelectionFilter,
        dpCellDatePicker: CustomDateCellEditor,
        dpNumericCellEditor: NumericCellEditor,
      },
      errorColmuns: [
        { headerName: "JANCODE", field: "janCode" },
        {
          headerName: "エラー内容",
          field: "errorMessage",
          wrapText: true,
          autoHeight: true,
          cellRenderer: function (param) {
            return param.data.errorMessage.join("<br>");
          },
        },
      ],
      errorRows: [],
      updateSuccessRecords: [],
      rules: {
        required: Validation.required,
        maxLength: Validation.maxLength,
        isNumber: Validation.isNumber,
        validSelectColumn: (value) => this.validSelectColumn(value),
        isShipDateRule: (value) => this.isShipDateRule(value),
        movingQuantityRules: (value) => this.movingQuantityRules(value),
      },
    };
  },
  watch: {
    inputModel(inputModel) {
      this.init(inputModel);
    },
  },
  computed: {
    isLoading() {
      return this.$store.getters["ui/isLoading"];
    },
  },
  methods: {
    async onGridReady(params) {
      this.gridApi = params.api;
      this.columnApi = params.columnApi;

      await this.init(this.inputModel);

      var allColumnIds = [];
      this.columnApi.getAllColumns().forEach(function (column) {
        allColumnIds.push(column.colId);
      });
      this.columnApi.autoSizeColumns(allColumnIds);
      this.gridApi.refreshCells();
    },
    onSelectionChanged() {
      this.selectedRows = this.gridApi.getSelectedRows();
    },
    onColumnEverythingChanged() {
      var allColumnIds = [];
      this.columnApi?.getAllColumns().forEach(function (column) {
        allColumnIds.push(column.colId);
      });
      this.columnApi?.autoSizeColumns(allColumnIds);
      this.gridApi?.refreshCells();
    },
    onBtnSetUpdate() {
      if (this.validate()) {
        this.selectedRows.forEach((row) => {
          this.settingSources.forEach((source) => {
            if (source.column === this.constant.StockMoveBulkEditColumns.MOVING_PLAN_DATE) {
              row.movingPlanDate = source.value ?? row.movingPlanDate;
              row.updateMovingPlanDate = true;
            } else if (source.column === this.constant.StockMoveBulkEditColumns.SPECIFIED_DELIVERY_DATE) {
              row.specifiedDeliveryDate = source.value ?? row.specifiedDeliveryDate;
              row.updateSpecifiedDeliveryDate = true;
            } else if (source.column === this.constant.StockMoveBulkEditColumns.MOVING_QUANTITY) {
              row.movingQuantity = source.value;
              row.updateMovingQuantity = true;
            } else if (source.column === this.constant.StockMoveBulkEditColumns.IS_READY) {
              row.isReady = source.value;
              row.updateIsReady = true;
            }
          });
        });
        this.gridApi.applyTransaction({ update: this.selectedRows });
        this.updateModel = this.defaultModel;
      }
    },
    async initTransferModel(inputModel) {
      const transferMondels = [];
      if (inputModel) {
        for (let row of inputModel) {
          var params = { janCode: row.janCode, storageCode: row.storageCode };
          if (row.arrivalId) {
            params.storageCode = row.movingWarehouseCode;
          }
          const response = await this.$store.dispatch("stock/search", params);
          if (ApiStatus.isSystemError(response.data?.header)) {
            return this.redirectError();
          }
          let transferModel = {
            shippingInstructionId: row.shippingInstructionId,
            arrivalId: row.arrivalId,
            productCode: row.productCode,
            janCode: row.janCode,
            title: row.title,
            productName: row.productName,
            storageCode: !row.arrivalId ? row.storageCode : row.movingWarehouseCode,
            currentStorageName: !row.arrivalId ? row.storageName : row.movingWarehouseName,
            currentStockQuantity:
              response.data.contents.stocks.length > 0 ? response.data.contents.stocks[0].stockQuantity : 0,
            badQuantity: response.data.contents.stocks.length > 0 ? response.data.contents.stocks[0].badQuantity : 0,
            movingPlanDate: row.shipDate,
            specifiedDeliveryDate: row.specifiedDeliveryDate,
            movingStorageCode: !row.arrivalId ? Number(row.movingWarehouseCode) : Number(row.warehouseCode),
            movingQuantity: !row.arrivalId ? row.orderQuantity : row.orderPlanQuantity,
            productConditionType: row.productConditionType,
            updateDatetime: row.updateDatetime,
            isReady: row.isReady,
            remarks: row.remarks,
            slipNo: row.slipNo,
          };
          if (row.productConditionType === FtProductConditionType.GOOD) {
            transferModel.currentStockQuantity += row.orderQuantity;
          } else {
            transferModel.badQuantity += row.orderQuantity;
          }
          transferMondels.push(transferModel);
        }
      }
      return transferMondels;
    },
    async init(inputModel) {
      this.updateModel = this.defaultModel;
      let rowModel = await this.initTransferModel(inputModel);
      this.gridApi.setRowData(JSON.parse(JSON.stringify(rowModel)));
      this.updateSuccessRecords = [];
      // 初期表示時、設定項目を１つ表示する。
      this.onAddSettingSource();
      if (this.$refs.StockMoveBulkEditForm) {
        this.$refs.StockMoveBulkEditForm.resetValidation();
      }
    },
    reset() {
      this.init(this.inputModel);
      this.updateSuccessRecords = [];
    },
    onCancelClick() {
      this.$emit("cancel", this.updateSuccessRecords);
    },
    async onUpdateClick() {
      var requestRecords = [];
      this.errorRows = [];
      var isValid = true;
      console.log(this.selectedRows);
      this.gridApi.forEachNode((record) => {
        console.log(record);
        var error = this.validateRow(record.data);
        if (error != true) {
          this.errorRows.push({
            janCode: record.data.janCode,
            errorMessage: error,
          });
          isValid = false;
        } else {
          requestRecords.push(this.requestFormat(record.data));
        }
      });
      if (isValid) {
        try {
          this.loadingOn();
          // バッチ化のためListを直接送信するよう変更
          // var param = {
          //   shipments: requestRecords,
          // };
          const response = await this.$store.dispatch("transfer/bulkupdate", { transfers: requestRecords });
          let error = response.data?.header;
          // 一覧更新レコード
          let updateRows = [];
          this.errorRows = [];
          switch (error.resultCode) {
            case ApiStatus.consts.SUCCESS:
            case ApiStatus.consts.BUSINESS_ERROR:
            case ApiStatus.consts.ALREADY_CHANGED:
              // エラーメッセージ格納
              if (error.messages) {
                Object.keys(error.messages).forEach((key) => {
                  this.errorRows.push({
                    shippingInstructionId: key,
                    janCode: requestRecords.find((elm) => elm.shippingInstructionId == key)?.janCode,
                    errorMessage: error.messages[key],
                  });
                });
              }
              // 一覧部の更新分のレコード設定
              if (response.data.contents) {
                this.gridApi.forEachNode((row) => {
                  response.data.contents.transfers.forEach((shipment) => {
                    let isError = this.errorRows.some(
                      (r) => r.shippingInstructionId === row.data.shippingInstructionId
                    );
                    if (shipment.shippingInstructionId == row.data.shippingInstructionId && !isError) {
                      this.updateSuccessRecords.push(shipment);
                      updateRows.push(shipment);
                    }
                  });
                });
              }
              // エラー表示
              if (this.errorRows.length > 0) {
                // 確認ダイアログから更新した分を削除(エラーがある場合のみ)
                this.gridApi.applyTransaction({ remove: updateRows });
                this.$refs.updateErrorGrid.open({ records: this.errorRows });
                this.$dialog.notify.error(`移動指示の更新処理に失敗したデータが存在します。ご確認ください。`, {
                  timeout: 2300,
                });
              } else {
                this.$dialog.notify.info(`移動指示を変更しました`, { timeout: 2300 });
                this.$refs.updateErrorGrid.close();
                this.$emit("updated", this.updateSuccessRecords);
                this.reset();
              }
              break;
            default:
              this.redirectError();
              break;
          }
        } catch (error) {
          console.error("StockMoveBulkEdit::onUpdateClick", error);
          this.apiRequestError(error);
        } finally {
          this.loadingOff();
        }
      } else {
        this.$refs.updateErrorGrid.open({ records: this.errorRows });
        this.$dialog.notify.error(`更新データに入力エラーが存在します。ご確認ください。`, {
          timeout: 2300,
        });
      }
    },
    validate() {
      const isValid = this.$refs.StockMoveBulkEditForm.validate();
      if (!isValid) {
        this.$dialog.notify.error(`入力エラーがあります`, { timeout: 2300 });
      }
      return isValid;
    },
    validateRow(row) {
      var ret = true;
      var messages = [];
      console.log("row:::");
      console.log(row);
      // 出荷日
      this.setValidMessage(this.rules.required(row.movingPlanDate), "移動予定日", messages);
      this.setValidMessage(this.isDate(row.movingPlanDate), "移動予定日", messages);
      this.setValidMessage(this.isShipDateRule(row.movingPlanDate), "移動予定日", messages);
      // 配送指定日
      this.setValidMessage(this.isDate(row.specifiedDeliveryDate), "配送指定日", messages);
      this.setValidMessage(
        this.isSpecifiedDeliveryDateRule(row.specifiedDeliveryDate, row.movingPlanDate),
        "配送指定日",
        messages
      );
      this.setValidMessage(this.rules.required(row.movingQuantity), "移動数量", messages);
      this.setValidMessage(this.rules.isNumber(row.movingQuantity), "移動数量", messages);
      this.setValidMessage(this.rules.maxLength(8)(row.movingQuantity), "移動数量", messages);
      this.setValidMessage(this.movingQuantityRules(row), "移動数量", messages);
      if (messages.length > 0) ret = messages;
      return ret;
    },
    isDate(value) {
      if (value == null || value == "") return true;
      if (!moment(value, "YYYY-MM-DD", true).isValid()) return "YYYY/MM/DD形式で入力してください";
      return true;
    },
    isShipDateRule(value) {
      if (value == null) return true;
      if (!moment(value).isSameOrAfter(moment().format("YYYY-MM-DD"))) return "現在日以降を入力してください";
      return true;
    },
    isSpecifiedDeliveryDateRule(value, compareValue) {
      if (value == null || compareValue == null) return true;
      if (!moment(value).isSameOrAfter(moment(compareValue))) return "移動予定日以降を入力してください";
      return true;
    },
    maxLength(value, length) {
      if (value == null || value == "") return true;
      var ret = false;
      if (typeof value == "number") {
        if (String(value).length <= length) {
          ret = true;
        }
      } else {
        if (value.length <= length) {
          ret = true;
        }
      }
      return ret;
    },
    requestFormat(row) {
      return {
        shippingInstructionId: row.shippingInstructionId,
        arrivalId: row.arrivalId,
        shipDate: row.movingPlanDate,
        specifiedDeliveryDate: row.specifiedDeliveryDate,
        productCode: row.productCode,
        storageCode: String(row.storageCode),
        stockQuantity: Number(row.movingQuantity),
        movingStorageCode: String(row.movingStorageCode),
        isReady: Boolean(row.isReady),
        updateDatetime: row.updateDatetime,
        janCode: row.janCode,
      };
    },
    setValidMessage(check, culumnName, messages) {
      if (!(check === true)) messages.push(culumnName + "は" + check);
    },
    onCellEditingStarted(event) {
      console.log("cellEditingStarted " + event.column.colId);
      const popupTop = event.api.getCellEditorInstances()[0].getGui().style.top;
      event.api.getCellEditorInstances()[0].getGui().style.top = parseInt(popupTop) - 40 + "px";
    },
    onCellValueChanged(params) {
      if (params.colDef.field == "movingPlanDate") {
        params.data.updateMovingPlanDate = true;
      } else if (params.colDef.field == "specifiedDeliveryDate") {
        params.data.updateSpecifiedDeliveryDate = true;
      } else if (params.colDef.field == "isReady") {
        params.data.updateIsReady = true;
      } else if (params.colDef.field == "movingQuantity") {
        params.data.updateMovingQuantity = true;
      }
      let rows = [];
      rows.push(params.node);
      this.gridApi.refreshCells({ force: true, rowNodes: rows });
    },
    onSettingColSelected(index) {
      this.settingSources[index].value = "";
    },
    onAddSettingSource() {
      let settingSource = {
        column: 0,
        value: "",
      };
      this.settingSources.push(settingSource);
    },
    onDelSettingSource(index) {
      this.settingSources.splice(index, 1);
    },
    validSelectColumn(value) {
      if (value == null) return true;
      if (this.settingSources.filter((settingSource) => settingSource.column === value).length > 1)
        return "同一項目が選択されています。";
      return true;
    },
    movingQuantityRules(row) {
      if (row?.movingQuantity == null) return true;
      if (Number(row.movingQuantity) < 0) return "0以上を入力してください";
      if (row.productConditionType === 10) {
        if (Number(row.movingQuantity) > Number(row.currentStockQuantity)) return "現在庫数以下を入力してください";
      } else {
        if (Number(row.movingQuantity) > Number(row.badQuantity)) return "不良在庫数以下を入力してください";
      }
      return true;
    },
  },
  beforeMount() {
    this.domLayout = "autoHeight";
    this.defaultColDef = {
      filter: "agTextColumnFilter",
      resizable: true,
      sortable: true,
      suppressSizeToFit: true,
      filterParams: {
        newRowsAction: "keep",
      },
    };
    this.columnDefs = [
      {
        headerName: "",
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true,
        checkboxSelection: true,
        filter: false,
        resizable: false,
        sortable: false,
        pinned: "left",
      },
    ];
    this.columnDefs = this.columnDefs.concat(ShippingInstructionColumnDefs);
  },
};
</script>
