<template>
  <difference-select
    @onDialogClose="onCancelClick"
    @onDialogSetting="onDifferenceSelect"
    :differenceList="differenceList"
    title="差分対処"
  ></difference-select>
</template>

<script>
import DifferenceSelect from "../../components/stock/DifferenceSelect.vue";
import { statuses as ApiStatus } from "../../libs/api-client";
import ShipmentAchievmentDifference from "../../consts/difference/ShipmentAchievmentDifferences";

export default {
  name: "ShipmentAchievmentDifferenceSelect",
  props: ["inputModel"],
  components: {
    DifferenceSelect,
  },
  data() {
    return {
      updateModel: null,
      differenceList: [],
    };
  },
  beforeMount() {
    this.init(this.inputModel);
  },
  watch: {
    inputModel(inputModel) {
      this.init(inputModel);
    },
  },
  methods: {
    async init(inputModel) {
      this.initUpdateModel(inputModel);
      this.differenceList = ShipmentAchievmentDifference.all();
    },
    initUpdateModel(inputModel) {
      this.updateModel = {
        shippingInstructionId: inputModel.shippingInstructionId,
        updateDatetime: inputModel.updateDatetime,
      };
    },
    reset() {
      this.init(this.inputModel);
    },
    onCancelClick() {
      this.$emit("cancel");
    },
    async onDifferenceSelect(differenceCope) {
      try {
        this.loadingOn();
        const updateModel = {
          shippingInstructionId: this.updateModel.shippingInstructionId,
          differenceCope: Number(differenceCope),
          updateDatetime: this.updateModel.updateDatetime,
        };
        const response = await this.$store.dispatch("shipmentAchievement/handle", updateModel);
        switch (response.data?.header.resultCode) {
          case ApiStatus.consts.SUCCESS:
            this.$dialog.notify.info("差分を対処しました", { timeout: 2300 });
            var shipmentAchievementRecord = response.data.contents.shipmentAchievement;
            this.$emit("updated", shipmentAchievementRecord);
            this.reset();
            break;
          case ApiStatus.consts.BUSINESS_ERROR:
            var message = this.setError(response.data?.header.messages);
            if (message != "") {
              this.$dialog.warning({
                title: "差分対処",
                text: message,
                actions: ["OK"],
              });
            }
            break;
          case ApiStatus.consts.ALREADY_CHANGED:
            this.$dialog.warning({
              title: "差分対処",
              text: response.data?.header.messages.NotItem?.join("<br>"),
              actions: ["OK"],
            });
            break;
          default:
            this.redirectError();
            break;
        }
      } catch (error) {
        console.error("ArrivalAchievmentDifferenceSelect::onDifferenceSelect", error);
        this.apiRequestError(error);
      } finally {
        this.loadingOff();
      }
    },
    setError(responseMessage) {
      var messages = [];
      var message = "";
      if (responseMessage?.NotItem) {
        messages.push(responseMessage.NotItem?.join("<br>"));
      }
      if (messages.length > 0) message = messages.join("<br>");
      return message;
    },
  },
};
</script>
